import React, { useMemo } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { menuLinks } from '@/utils/constants';
//import { setTextOpacity } from '@/utils/methods';

const BreadCrumb: React.FC = () => {
  const location = useLocation();
  //const params = useParams();
  const pathname = location.pathname;
  //const paramsProduct = params.product;
  //
  const isHome = pathname === '/';
  const isCatalog = pathname.match(/^\/catalog\/[A-Za-z0-9-]{1,}\/?$/);
  const isProduct = pathname.match(/^\/catalog\/[A-Za-z0-9-]{1,}\/[A-Za-z0-9-]{1,}/);
  const productMatch = pathname.match(/([A-Za-z0-9-]{1,})\/([A-Za-z0-9-]{1,})\/([A-Za-z0-9-]{1,})/);
  //console.log(productMatch);

  const currentLink = useMemo(
    () => menuLinks?.find(link => link.path === pathname || link.path + '/' === pathname),
    [pathname],
  );
  const catalogLink = menuLinks?.find(({ path }) => path === '/catalog');

  const isMain = !isHome && !isCatalog && !isProduct && !!currentLink;

  const currentLinkCatalog = useMemo(
    () =>
      catalogLink.subMenu?.find(link => {
        const newReg = new RegExp(link.path);
        const regPath = pathname.match(newReg);
        //console.log(regPath);
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (!!regPath) return true;
        else return false;
      }),
    [catalogLink.subMenu, pathname],
  );

  // const currentSubService = useMemo(
  //   () => currentLinkCatalog?.subMenu?.find(link => link.path === pathname),
  //   [pathname, currentLinkCatalog],
  // );

  return (
    <section style={isHome ? { display: 'none' } : {}}>
      {(isHome || !currentLink) && <></>}

      {isMain && (
        <div id="breadCrumb-js" className="breadCrumbWrapper container">
          <ul itemScope itemType="https://schema.org/BreadcrumbList" className="breadCrumbList">
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/" className="link">
                <span itemProp="name">Главная</span>
              </Link>
              <meta itemProp="position" content="1" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item breadCrumbList__item_static"
            >
              <span itemProp="item" className="static">
                <span itemProp="name">{currentLink?.name}</span>
              </span>
              <meta itemProp="position" content="2" />
            </li>
          </ul>
        </div>
      )}

      {!isHome && isCatalog && (
        <div id="menu-opacity-0" className="breadCrumbWrapper container">
          <ul itemScope itemType="https://schema.org/BreadcrumbList" className="breadCrumbList">
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/" className="link">
                <span itemProp="name">Главная</span>
              </Link>
              <meta itemProp="position" content="1" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/catalog" className="link">
                <span itemProp="name">Каталог</span>
              </Link>
              <meta itemProp="position" content="2" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item breadCrumbList__item_static"
            >
              <span itemProp="item" className="static">
                <span itemProp="name">{currentLinkCatalog?.name}</span>
              </span>
              <meta itemProp="position" content="3" />
            </li>
          </ul>
        </div>
      )}

      {!isHome && isProduct && (
        <div id="menu-opacity-0" className="breadCrumbWrapper container">
          <ul itemScope itemType="https://schema.org/BreadcrumbList" className="breadCrumbList">
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/" className="link">
                <span itemProp="name">Главная</span>
              </Link>
              <meta itemProp="position" content="1" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/catalog" className="link">
                <span itemProp="name">Каталог</span>
              </Link>
              <meta itemProp="position" content="2" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to={`${currentLinkCatalog?.path}`} className="link">
                <span itemProp="name">{currentLinkCatalog?.name}</span>
              </Link>
              <meta itemProp="position" content="3" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item breadCrumbList__item_static"
            >
              <span itemProp="item" className="static">
                <span itemProp="name">{productMatch[3]}</span>
              </span>
              <meta itemProp="position" content="4" />
            </li>
          </ul>
        </div>
      )}

      {/* {!isHome && isSubServices && (
        <div id="menu-opacity-0" className="breadCrumbWrapper container textOpacity">
          <ul itemScope itemType="https://schema.org/BreadcrumbList" className="breadCrumbList">
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/" className="link">
                <span itemProp="name">Главная</span>
              </Link>
              <meta itemProp="position" content="1" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to="/services" className="link">
                <span itemProp="name">Услуги</span>
              </Link>
              <meta itemProp="position" content="2" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item"
            >
              <Link itemProp="item" to={`${currentService?.path}`} className="link">
                <span itemProp="name">{currentService?.name}</span>
              </Link>
              <meta itemProp="position" content="3" />
            </li>
            <li
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              className="breadCrumbList__item breadCrumbList__item_static"
            >
              <span itemProp="item" className="static">
                <span itemProp="name">{currentSubService?.name}</span>
              </span>
              <meta itemProp="position" content="4" />
            </li>
          </ul>
        </div>
      )} */}
    </section>
  );
};

export default BreadCrumb;
