import { RootState } from '..';

export function selectAdmin(state: RootState) {
  return state.admin;
}

export function adminLoading(state: RootState) {
  return state.admin.request.status === 1;
}

export function adminLoaded(state: RootState) {
  return state.admin.request.status === 2;
}

export function adminFailure(state: RootState) {
  return state.admin.request.error;
}

export function dataLoading(state: RootState) {
  return state.admin.requestData.status === 1;
}

export function dataLoaded(state: RootState) {
  return state.admin.requestData.status === 2;
}

export function dataFailure(state: RootState) {
  return state.admin.requestData.error;
}
