/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { onValue, ref, set } from 'firebase/database';
import { db } from '../../services/firebase';
import { AppDispatch } from '@store/index';
import { SupportMsg } from '@/utils/mainTypes';
import { getCodeDb, getDates } from '@/utils/methods';

const initialState = {
  data: [],
  visit: null,
  request: {
    status: 0,
    error: null,
  },
  requestSelection: {
    status: 0,
    error: null,
  },
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    pending: state => {
      state.request.status = 1;
    },
    loaded: state => {
      state.request.status = 2;
    },
    failure: (state, { payload }) => {
      state.request.status = 3;
      state.request.error = payload;
    },
    setVisit: (state, { payload }) => {
      state.visit = payload;
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
    //
    pendingSelection: state => {
      state.requestSelection.status = 1;
    },
    loadedSelection: state => {
      state.requestSelection.status = 2;
    },
    failureSelection: (state, { payload }) => {
      state.requestSelection.status = 3;
      state.requestSelection.error = payload;
    },
    //
    clear: state => {
      state.data = [];
      //state.visit = null;
      state.request.status = 0;
      state.request.error = null;
      state.requestSelection.status = 0;
      state.requestSelection.error = null;
    },
  },
});

const {
  pending,
  loaded,
  failure,
  setVisit,
  pendingSelection,
  loadedSelection,
  failureSelection,
  //
  clear,
} = feedbackSlice.actions;
export default feedbackSlice.reducer;

// Feedback

export const sendFeedbackData = (data: FormData) => async (dispatch: AppDispatch) => {
  dispatch(pending());

  try {
    const res = await fetch('/scripts/mailer/send_mail.php', {
      method: 'POST',
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: data,
    });
    //console.log(res);
    if (!!res.ok) dispatch(loaded());
    else dispatch(failure('Ошибка: ' + res.statusText));
    //
  } catch (err) {
    console.log(err);
    dispatch(failure('Ошибка: ' + err.message));
  }
};

export const sendSelectionData = (data: FormData) => async (dispatch: AppDispatch) => {
  dispatch(pendingSelection());

  try {
    const res = await fetch('/scripts/mailer/send_parts.php', {
      method: 'POST',
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: data,
    });
    console.log(res);
    if (!!res.ok) dispatch(loadedSelection());
    else dispatch(failureSelection('Ошибка: ' + res.statusText));
    //
  } catch (err) {
    console.log(err);
    dispatch(failureSelection('Ошибка: ' + err.message));
  }
};

//

export const sendSupportMsg = (msg: SupportMsg) => (dispatch: AppDispatch) => {
  dispatch(pending());
  //const authId = auth.currentUser.uid;
  //const formData = new FormData();
  let msgId = null;

  onValue(
    ref(db, 'supportMsgs'),
    snapshot => {
      const list = Object.values(snapshot.val() || {});
      const lastMsg = list[list.length - 1] as SupportMsg;
      //const randomId = 0 + Date.now().toString().slice(10);
      msgId = lastMsg?.id + 1 || 1;

      const dbRefMsg = ref(db, `supportMsgs/${msgId}`);
      const newMsg = { ...msg, id: msgId };

      set(dbRefMsg, newMsg)
        .then(() => {
          dispatch(loaded());
        })
        .catch(err => {
          console.log(err);
          dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
        });
      //
    },
    err => {
      console.log(err);
      dispatch(failure('Ошибка: ' + getCodeDb(err.name)));
    },
    {
      onlyOnce: true,
    },
  );
};

export const addVisitDb = () => (dispatch: AppDispatch) => {
  //dispatch(pending());
  const year = getDates().year;
  const month = getDates().month;
  const day = getDates().day;
  const dbRef = ref(db, `visits/${year}/${month}/${day}`);
  const obj = {
    today: true,
    date: day + month,
    //date: new Date().getDate(),
    //date: getVisitDate(),
  };

  onValue(
    dbRef,
    snapshot => {
      const lastVisit = snapshot.val() || 0;
      //console.log(lastVisit);

      set(dbRef, lastVisit + 1)
        .then(() => {
          dispatch(setVisit(obj));
        })
        .catch(err => {
          console.log(err);
          dispatch(setVisit(null));
          //dispatch(failure('Ошибка: ' + err.message.slice(16)));
        });
    },
    err => {
      console.log(err);
      //dispatch(failure('Ошибка: ' + err.message.slice(16)));
    },
    {
      onlyOnce: true,
    },
  );
};

export const clearFeedback = () => (dispatch: AppDispatch) => {
  dispatch(clear());
};
