/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { onValue, ref, set, update } from 'firebase/database';
import { auth, db } from '../../services/firebase';
import { AppDispatch } from '@store/index';
import { Order, Product, Subscription } from '@/utils/mainTypes';
import { formatRegistryDate, getCodeDb } from '@/utils/methods';
import { partsBrands } from '@/utils/constants';

const initialState = {
  orders: [],
  catalogList: [],
  cartList: [],
  findedList: [],
  findedProduct: null,
  payment: null,
  request: {
    status: 0,
    error: null,
  },
  requestPayment: {
    status: 0,
    error: null,
  },
  requestData: {
    status: 0,
    error: null,
  },
  requestCart: {
    status: 0,
    error: null,
  },
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    // Orders
    pending: state => {
      state.request.status = 1;
    },
    success: state => {
      state.request.status = 2;
    },
    successOrder: state => {
      state.request.status = 4;
    },
    successPayment: state => {
      state.requestPayment.status = 6;
    },
    failure: (state, { payload }) => {
      state.request.status = 3;
      state.request.error = payload;
    },
    setOrders: (state, { payload }) => {
      state.orders = payload;
      state.request.status = 2;
      state.request.error = null;
    },
    setPayment: (state, { payload }) => {
      state.payment = payload;
      state.requestPayment.status = 5;
      // state.request.error = null;
    },
    // Catalog
    pendingData: state => {
      state.requestData.status = 1;
    },
    successData: state => {
      state.requestData.status = 2;
    },
    setCatalog: (state, { payload }) => {
      state.catalogList = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setFindedList: (state, { payload }) => {
      state.findedList = [...state.findedList, ...payload];
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setProduct: (state, { payload }) => {
      state.findedProduct = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    failureData: (state, { payload }) => {
      state.requestData.status = 3;
      state.requestData.error = payload;
    },
    // Cart
    pendingCart: state => {
      state.requestCart.status = 1;
    },
    successCart: state => {
      state.requestCart.status = 2;
    },
    failureCart: (state, { payload }) => {
      state.requestCart.status = 3;
      state.requestCart.error = payload;
    },
    setToCart: (state, { payload }) => {
      state.requestCart.status = 2;
      state.requestCart.error = null;
      //state.cartList = [...state.cartList, payload];
      if (state.cartList.length < 10) state.cartList.unshift(payload);
      else {
        state.requestCart.status = 3;
        state.requestCart.error =
          'Превышен лимит товаров в корзине. Пожалуйста, оформите заказ, потом сформируйте новый';
        //return;
      }
    },
    changeCart: (state, { payload }) => {
      const i = state.cartList.findIndex(({ id }) => id === payload?.id);
      const newList = [...state.cartList];
      newList[i] = payload;
      state.cartList = newList;
      state.requestCart.status = 2;
      state.requestCart.error = null;
    },
    removeFromCart: (state, { payload }) => {
      const i = state.cartList.findIndex(({ id }) => id === payload?.id);
      //console.log(i);
      const newList = [...state.cartList];
      newList.splice(i, 1);
      state.cartList = newList;
      state.requestCart.status = 2;
      state.requestCart.error = null;
    },
    //Clear
    clearCart: state => {
      state.cartList = [];
    },
    clearPayment: state => {
      state.payment = null;
      state.requestPayment.status = 0;
      state.requestPayment.error = null;
    },
    clearFindedList: state => {
      state.findedList = [];
    },
    clear: state => {
      state.orders = [];
      //state.catalogList = [];
      //state.cartList = [];
      state.findedList = [];
      state.findedProduct = null;
      state.request.status = 0;
      state.request.error = null;
      state.requestPayment.status = 0;
      state.requestPayment.error = null;
      state.requestData.status = 0;
      state.requestData.error = null;
      state.requestCart.status = 0;
      state.requestCart.error = null;
    },
  },
});

const {
  pending,
  success,
  failure,
  successOrder,
  successPayment,
  //setOrders,
  setPayment,
  pendingData,
  failureData,
  setCatalog,
  setFindedList,
  setProduct,
  //pendingCart,
  //failureCart,
  setToCart,
  changeCart,
  removeFromCart,
  clearCart,
  clearPayment,
  clearFindedList,
  clear,
} = ordersSlice.actions;
export default ordersSlice.reducer;

// export const initOrdersDb = (userId: number) => (dispatch: AppDispatch) => {
//   dispatch(pending());
//   //const id = auth.currentUser.uid;
//   const dbRef = ref(db, `orders/${userId}`);
//   onValue(
//     dbRef,
//     snapshot => {
//       const data = snapshot.val();
//       dispatch(setOrders(Object.values(data || {})));
//     },
//     err => {
//       console.log(err);
//       dispatch(failure('Ошибка: ' + err.message.slice(16)));
//     },
//   );
// };

// Catalog

export const getCatalogDb = () => (dispatch: AppDispatch) => {
  dispatch(pendingData());
  //const authId = auth.currentUser.uid;

  onValue(
    ref(db, 'catalog'),
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      //const user = data.find(({ id }) => id === userId);
      dispatch(setCatalog(data));
    },
    err => {
      console.log(err);
      dispatch(failureData('Ошибка: ' + getCodeDb(err.name)));
    },
    // {
    //   onlyOnce: true,
    // },
  );
};

export const getPriceList = (text: string) => async (dispatch: AppDispatch) => {
  dispatch(pendingData());
  dispatch(clearFindedList());
  const reg = new RegExp(`${text}`, 'i');
  let isFinded = false;
  //let list = [];

  partsBrands.forEach(async (brand: string, idx) => {
    //
    try {
      const res = await fetch(`/scripts/parts/prices/${brand}.json`);
      const data = await res.json();
      //console.log(data);
      const productList = (data?.list as Array<Product>) || [];

      if (res.ok && !!productList.length) {
        const filtered = productList?.filter(
          ({ code, name }) => name?.match(reg) || code?.match(reg),
        );
        //console.log(filtered);
        //if (filtered?.length > 0) list = [...list, filtered];
        if (filtered?.length > 0) {
          dispatch(setFindedList(filtered));
          isFinded = true;
        }
        //else dispatch(setFindedList([]));
      }

      if (partsBrands.length === idx + 1 && !isFinded) {
        dispatch(setFindedList([]));
      }
      //
    } catch (err) {
      console.log(err.message);
      //dispatch(failureData('Ошибка: ' + err));
    }
  });
};

export const getProduct = (productCode: string) => async (dispatch: AppDispatch) => {
  dispatch(pendingData());
  //dispatch(setProduct(null));
  let findedValue = null;
  let findedError = '';

  await partsBrands.forEach(async (brand: string, idx) => {
    //
    try {
      //dispatch(pendingData());
      const res = await fetch(`/scripts/parts/prices/${brand}.json`);
      const data = await res.json();
      //console.log(data);
      const productList = (data?.list as Array<Product>) || [];

      if (res.ok && !!productList.length && !findedValue) {
        const finded = productList?.find(({ code }) => code === productCode);
        //console.log(findedValue);
        if (!!finded) {
          dispatch(setProduct(finded));
          findedValue = finded;
          findedError = '';
          //throw new Error('finded');
          // throw {
          //   reason: 'finded',
          // };
        } else findedError = '404: Продукт не найден';
      }

      //console.log(findedError);
      if (partsBrands.length === idx + 1 && !!findedError) {
        dispatch(failureData(findedError));
      }
      //
    } catch (err) {
      console.log(err.message);
      //dispatch(failureData('Ошибка: ' + err));
    }
  });
};

// Cart

export const addToCart = (product: Product) => (dispatch: AppDispatch) => {
  //dispatch(pendingCart());

  dispatch(setToCart(product));
};

export const changeProductInCart = (product: Product) => (dispatch: AppDispatch) => {
  //dispatch(pendingCart());

  dispatch(changeCart(product));
};

export const deleteFromCart = (product: Product) => (dispatch: AppDispatch) => {
  //dispatch(pendingCart());

  dispatch(removeFromCart(product));
};

// Orders

export const sendOrder = (order: Order, userId?: number) => async (dispatch: AppDispatch) => {
  dispatch(pending());

  const authId = auth?.currentUser?.uid;
  let orderId = null;

  onValue(
    ref(db, 'orders'),
    async snapshot => {
      const list = Object.values(snapshot.val() || {});
      const lastOrder = list[list.length - 1] as Order;
      //const randomId = 0 + Date.now().toString().slice(10);
      const randomId = Date.now();
      orderId = lastOrder?.id + 1 || randomId;
      //console.log(authId);
      const orderName = 'Покупка автозапчастей компании "АвтоРемарка"';
      const orderText = `Заказ № ${orderId} от ${order.date}: Товары на сумму ${order.price}, кол-во ${order.amount}`;
      const newOrder = { ...order, id: orderId, text: orderText };

      const dbRefOrder = ref(db, `orders/${orderId}`);
      //const dbRefOrder = ref(db, `orders/${userId}`);

      set(dbRefOrder, newOrder)
        .then(() => {
          dispatch(successOrder());
          dispatch(clearCart());
        })
        .catch(err => {
          console.log(err);
          dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
        });

      if (!!authId) {
        const dbRefUser = ref(db, `users/${authId}`);
        const newPayment = {
          payment: {
            userId,
            orderId,
            orderName,
            orderText,
            status: 'pending',
            paid: false,
            order: newOrder,
            //date: formatRegistryDate(),
          },
        };
        //
        update(dbRefUser, newPayment)
          .then(() => {
            //dispatch(successPayment());
            dispatch(setPayment(newPayment.payment));
          })
          .catch(err => {
            console.log(err);
            dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
          });
        //
      } else {
        const payment = {
          orderId,
          orderName,
          orderText,
          status: 'pending',
          paid: false,
          order: newOrder,
        };
        //
        dispatch(setPayment(payment));
      }

      // formData.append('orderId', String(orderId));
      // formData.append('userId', String(userId));
      // formData.append('price', order.price);
      // formData.append('description', text);

      // try {
      //   const req = await fetch('/scripts/order/payment.php', {
      //     method: 'POST',
      //     //headers: { 'Content-Type': 'multipart/form-data' },
      //     body: formData,
      //     mode: 'no-cors',
      //   });
      //   console.log(req);
      //   const resText = await req.text();
      //   console.log(resText);
      //   console.log(resText.toString);
      //   //console.log(JSON.parse(resText));
      //   console.log(JSON.stringify(resText));

      //   if (req.status === 204) {
      //     //dispatch(success());
      //     dispatch(failure('Ошибка оформления заказа, попробуйте ещё раз'));
      //   } // else navigate('/profile');
      // } catch (err) {
      //   console.log(err);
      //   dispatch(failure('Ошибка: ' + err));
      // }
    },
    err => {
      console.log(err);
      dispatch(failure('Ошибка: ' + getCodeDb(err.name)));
    },
    {
      onlyOnce: true,
    },
  );
};

export const getOrder = (sub: Subscription, userId: number) => async (dispatch: AppDispatch) => {
  dispatch(pending());
  const authId = auth.currentUser.uid;
  const dbRefOrder = ref(db, `orders/${sub.orderId}`);
  const dbRefUser = ref(db, `users/${authId}`);
  const formData = new FormData();
  formData.append('orderId', String(sub.orderId));
  formData.append('userId', String(userId));

  try {
    const req = await fetch('/scripts/order/get_payment.php', {
      method: 'POST',
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: formData,
      mode: 'no-cors',
    });
    //console.log(req);
    const res = await req.text();
    console.log(res);
    //console.log(JSON.stringify(res));
    const jsonStr = JSON.stringify(res);
    const regPaid = jsonStr.match(/\[paid.{1,5}1/ims);
    //console.log(regPaid);

    if (req.status === 200 && !!regPaid) {
      dispatch(success());
      const newOrder = {
        paid: true,
      };
      const newSub = {
        subscription: { ...sub, pending: false, paid: true },
      };

      update(dbRefOrder, newOrder)
        .then(() => {
          //
        })
        .catch(err => {
          console.log(err);
          dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
        });
      update(dbRefUser, newSub)
        .then(() => {
          //
        })
        .catch(err => {
          console.log(err);
          dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
        });
    }

    if (req.status === 202) {
      dispatch(pending());
    }

    if (req.status === 204) {
      const newSub = {
        subscription: { ...sub, date: '', deadline: '', pending: false, paid: false },
      };
      update(dbRefUser, newSub)
        .then(() => {
          //
        })
        .catch(err => {
          console.log(err);
          dispatch(failure('Ошибка: ' + getCodeDb(err.code)));
        });
      //
      dispatch(failure('Ошибка оплаты заказа'));
    }

    if (req.status === 500 || req.status === 501) {
      dispatch(
        failure(
          `Ошибка проверки заказа, обратитесь в службу поддержки. Номер заказа: ${sub.orderId}`,
        ),
      );
    }
  } catch (err) {
    console.log(err);
    dispatch(failure('Ошибка: ' + err));
  }
};

export const clearOrder = () => (dispatch: AppDispatch) => {
  dispatch(clearPayment());
};

export const clearOrders = () => (dispatch: AppDispatch) => {
  dispatch(clear());
};
