import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
//
import { feedbackPending } from '@/store/feedback/selectors';
import { modalStyle } from '@/utils/constants';
import { CloseBtn } from '@/assets/images/Icons';
import { PropsModal } from '../types';
import FeedbackModal from './Feedback';
import FeedbackPartsModal from './FeedbackParts';

const FeedbackWrapperModal: React.FC<PropsModal> = React.memo((props: PropsModal) => {
  const loading = useSelector(feedbackPending, shallowEqual);
  //const loaded = useSelector(feedbackLoaded, shallowEqual);
  //const feedbackError = useSelector(feedbackFailure, shallowEqual);
  const [modalFeedback, setModalFeedback] = useState(false);
  const [modalParts, setModalParts] = useState(false);
  const [dialogError, setDialogError] = useState('');

  const { settings } = props;
  const { openModal, setOpenModal } = settings;

  // useEffect(() => {
  //   if (!!loaded) handleClose();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loading]);

  // useEffect(() => {
  //   if (!!feedbackError) setDialogError(feedbackError);
  // }, [feedbackError]);

  const handleClose = () => {
    setOpenModal(false);
    setDialogError('');

    setTimeout(() => {
      setModalFeedback(false);
      setModalParts(false);
    }, 500);
  };

  const handleOpenFeedback = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpenModal(false);
    setModalFeedback(true);
  };

  const handleOpenParts = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpenModal(false);
    setModalParts(true);
  };

  return (
    <>
      <Dialog open={openModal} onClose={handleClose} PaperProps={{ sx: modalStyle }}>
        <div className="mainDialog feedbackDialog">
          <button className="closeBtn mainBtn" onClick={handleClose}>
            {CloseBtn}
          </button>

          <DialogTitle className="heading">Выбор обращения</DialogTitle>

          <DialogContent className="content">
            <div className="feedbackLinks">
              <a className="link" onClick={handleOpenFeedback}>
                Обратная связь
              </a>
              <a className="link" onClick={handleOpenParts}>
                Подбор запчастей
              </a>
            </div>
          </DialogContent>

          {!!dialogError && <p className="dialogError">{dialogError}</p>}
          {loading && (
            <div className="dialogLoading">
              <CircularProgress className="progress" />
            </div>
          )}
        </div>
      </Dialog>

      <FeedbackModal
        settings={{
          openModal: modalFeedback,
          setOpenModal: setModalFeedback,
          mode: 'feedback',
        }}
      />

      <FeedbackPartsModal
        settings={{
          openModal: modalParts,
          setOpenModal: setModalParts,
        }}
        selection={true}
        data={null}
      />
    </>
  );
});

export default FeedbackWrapperModal;
