export default class Admin {
  id: number;
  dbId?: string;
  name?: string;
  email?: string;
  role?: string;
  registryDate?: string;
  visitDate?: string;
  gender?: string;
  subscription?: object;
  static id: number;

  constructor(id: number, dbId: string, name: string, role: string, visitDate: string) {
    this.id = id;
    this.dbId = dbId;
    this.name = name;
    this.role = role;
    this.visitDate = visitDate;
  }
}
