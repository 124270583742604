import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { mainColor } from '@/utils/constants';

const Home = React.lazy(() => import(/* webpackChunkName: "home" */ '@/scenes/Home'));
const Contacts = React.lazy(
  () => import(/* webpackChunkName: "extra" */ '@/scenes/ExtraPages/Contacts'),
);
const Catalog = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Catalog'));
const Category = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Catalog/Category'));
const Product = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Product'));
const Cart = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Cart'));
const Search = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/Search'));
const Profile = React.lazy(() => import(/* webpackChunkName: "profile" */ '@/scenes/Profile'));

//const Help = React.lazy(() => import(/* webpackChunkName: "extra" */ '@/scenes/ExtraPages/Help'));
const Policy = React.lazy(
  () => import(/* webpackChunkName: "extra" */ '@/scenes/ExtraPages/Policy'),
);
const UserPolicy = React.lazy(() => import(/* webpackChunkName: "extra" */ '@/scenes/ExtraPages/UserPolicy'));

const NotFound = React.lazy(() => import(/* webpackChunkName: "notfound" */ '@/scenes/NotFound'));
const Plug = React.lazy(() => import(/* webpackChunkName: "common" */ '@/scenes/ExtraPages/Plug'));

const AdminLogin = React.lazy(() => import(/* webpackChunkName: "admin" */ '@/scenes/AdminPanel/Login'));
const AdminLk = React.lazy(() => import(/* webpackChunkName: "admin" */ '@/scenes/AdminPanel/Lk'));

const Router: React.FC = () => {
  return (
    <Suspense
      fallback={
        <CircularProgress
          color="primary"
          sx={{
            position: 'absolute',
            top: '30%',
            left: '49%',
            color: mainColor,
          }}
        />
      }
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/catalog/:category" element={<Category />} />
        <Route path="/catalog/:category/:product" element={<Product />} />
        <Route path="/brands" element={<Plug />} />
        <Route path="/special-offers" element={<Plug />} />
        <Route path="/cooperation" element={<Plug />} />
        <Route path="/forum" element={<Plug />} />
        <Route path="/about" element={<Plug />} />
        <Route path="/news" element={<Plug />} />

        <Route path="/cart" element={<Cart />} />
        <Route path="/profile" element={<Profile />} />

        <Route path="/contacts" element={<Contacts />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/user-policy" element={<UserPolicy />} />

        <Route path="/search" element={<Search />} />

        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/admin/lk" element={<AdminLk />} />
        {/* <Route path="/admin/:login" element={<AdminLogin />} /> */}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
