import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';

import {
  selectionPending,
  selectionLoaded,
  selectionFailure,
  feedbackPending,
  feedbackLoaded,
  feedbackFailure,
} from '@/store/feedback/selectors';
//import { authFailure } from '@/store/auth/selectors';
import { cartFailure, orderFailure, orderLoaded, orderPending } from '@/store/orders/selectors';
import { User } from '@/utils/mainTypes';

interface Props {
  user?: User;
}

const DropSnackbar: React.FC<Props> = React.memo((props: Props) => {
  //const dispatch = useDispatch();
  // Parts
  const loadingParts = useSelector(selectionPending, shallowEqual);
  const loadedParts = useSelector(selectionLoaded, shallowEqual);
  const partsError = useSelector(selectionFailure, shallowEqual);
  // Feedback
  const loadingFeedback = useSelector(feedbackPending, shallowEqual);
  const loadedFeedback = useSelector(feedbackLoaded, shallowEqual);
  const feedbackError = useSelector(feedbackFailure, shallowEqual);
  // Order
  const loadingOrder = useSelector(orderPending, shallowEqual);
  const loadedOrder = useSelector(orderLoaded, shallowEqual);
  const orderError = useSelector(orderFailure, shallowEqual);
  // Cart
  const cartError = useSelector(cartFailure, shallowEqual);
  // State
  const [feedback, setFeedback] = useState({ open: false, msg: '' });
  const [error, setError] = useState({ open: false, msg: '' });

  const { user } = props;

  useEffect(() => {
    if (!!loadedParts) {
      setFeedback({
        open: true,
        msg: 'Данные для подбора отправлены. Наш эксперт свяжется с Вами вближайшее время!',
      });
    }

    if (!!loadedFeedback) {
      setFeedback({
        open: true,
        msg: 'Ваша заявка отправлена. Наш специалист свяжется с Вами вближайшее время!',
      });
    }

    if (!!loadedOrder) {
      if (!user?.id) {
        setFeedback({
          open: true,
          msg: 'Ваш заказ отправлен на подтверждение, ожидайте ответа',
        });
      } else {
        setFeedback({
          open: true,
          msg: 'Ваш заказ отправлен на подтверждение, статус заказа можно посмотреть в профиле',
        });
      }
    }
    //
  }, [loadingFeedback, loadingParts, loadingOrder]);

  // useEffect(() => {
  //   if (!!loadedFeedback)
  //     setFeedback({
  //       open: true,
  //       msg: 'Ваша заявка отправлена. Наш специалист свяжется с Вами вближайшее время!',
  //     });
  //   //
  // }, [loadingFeedback]);

  // Errors

  useEffect(() => {
    if (!!cartError) setError({ open: true, msg: cartError });
    //
  }, [cartError]);

  // const setLinkClass = (path: string) => {
  //   const newReg = new RegExp(path);
  //   const regPath = pathname.match(newReg);

  //   if (pathname === path) return 'menuList__item menuList__item_active';
  //   else if (!!regPath) return 'menuList__item menuList__item_active';
  //   else return 'menuList__item';
  // };

  return (
    <>
      <Snackbar
        open={feedback.open}
        autoHideDuration={5000}
        onClose={() => setFeedback({ open: false, msg: feedback.msg })}
        //message={feedback.msg}
        //action={action}
        className="snackbarWrapper"
        ContentProps={{ className: 'mainSnackbar' }}
      >
        <Alert severity="success" variant="standard" className="Alert">
          <span className="text">{feedback.msg}</span>
        </Alert>
      </Snackbar>

      <Snackbar
        open={error.open}
        autoHideDuration={5000}
        onClose={() => setError({ open: false, msg: error.msg })}
        //message={error.msg}
        //action={action}
        className="snackbarWrapper"
        ContentProps={{ className: 'mainSnackbar' }}
      >
        <Alert severity="error" variant="standard" className="Alert">
          {/* Ошибка загрузки <br /> */}
          <span className="textErr">{error.msg}</span>
        </Alert>
      </Snackbar>
    </>
  );
});

export default DropSnackbar;
