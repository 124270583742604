// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDh_4haOBl1ISZSOb9C6PCPxYKCbwYlKMY',
  authDomain: 'auto-parts-e7a5a.firebaseapp.com',
  projectId: 'auto-parts-e7a5a',
  storageBucket: 'auto-parts-e7a5a.appspot.com',
  messagingSenderId: '994301230387',
  appId: '1:994301230387:web:2ff8eab962f543ac1f9246',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getDatabase();

export const login = async (email: string, pass: string) => {
  await signInWithEmailAndPassword(auth, email, pass);
};

export const signUp = async (email: string, pass: string) => {
  await createUserWithEmailAndPassword(auth, email, pass);
};

export const logout = async () => {
  await signOut(auth);
};
