export default class User {
  id: string;
  dbId: string;
  activated: boolean;
  avatar: string;
  email: string;
  //subscription: object;
  personal: object;
  //interests: object;
  //aboutme: object;
  //gender: string;
  timeZone: string;
  verified: boolean;
  role: string;
  loggedIn?: boolean;
  static id: string;
  static email: string;
  static avatar: string;

  constructor(
    id: string,
    dbId: string,
    activated: boolean,
    email: string,
    avatar: string,
    personal: object,
    timeZone: string,
    verified: boolean,
    role: string,
    loggedIn?: boolean,
  ) {
    this.id = id;
    this.dbId = dbId;
    this.activated = activated;
    this.email = email;
    this.avatar = avatar;
    this.personal = personal;
    this.timeZone = timeZone;
    this.verified = verified;
    this.role = role;
    this.loggedIn = loggedIn;
  }
}
