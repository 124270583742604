import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import authSlicer from './auth/slicer';
import profileSlicer from './profile/slicer';
// import photosSlicer from './photos/slicer';
// import messagesSlicer from './messages/slicer';
// import sympathiesSlicer from './sympathies/slicer';
import ordersSlicer from './orders/slicer';
import feedbackSlicer from './feedback/slicer';
import adminSlicer from './admin/slicer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['auth', 'profile', 'feedback', 'orders', 'admin'],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['authed', 'activated', 'cookie'],
};

const profilePersistConfig = {
  key: 'profile',
  storage,
  whitelist: ['cookie', 'cityList'],
};

const ordersPersistConfig = {
  key: 'orders',
  storage,
  whitelist: ['cartList'],
};

const feedbackPersistConfig = {
  key: 'feedback',
  storage,
  whitelist: ['visit'],
};

const adminPersistConfig = {
  key: 'admin',
  storage,
  whitelist: ['authed'],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authSlicer),
  profile: persistReducer(profilePersistConfig, profileSlicer),
  orders: persistReducer(ordersPersistConfig, ordersSlicer),
  feedback: persistReducer(feedbackPersistConfig, feedbackSlicer),
  admin: persistReducer(adminPersistConfig, adminSlicer),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
