import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, useLocation } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
//import { selectAdmin } from '@/store/admin/selectors';
//import { adminLogout } from '@/store/admin/slicer';
//import { getUser } from '@/store/profile/selectors';
// import { changeUser, userLogout } from '@/store/profile/slicer';
// import { clearFeedback } from '@/store/feedback/slicer';
// import { formatVisitDate } from '@/utils/methods';
import { User } from '@/utils/mainTypes';
import { AccountBtn } from '@/assets/images/Icons';
import AuthModal from '@/components/modals/AuthModal';

interface Props {
  authed: boolean;
  user: User;
  navigate?: NavigateFunction;
  mode?: string;
  setMode?: React.Dispatch<React.SetStateAction<string>>;
}

const AuthMenu: React.FC<Props> = React.memo((props: Props) => {
  const dispatch = useDispatch();
  // const { pathname } = useLocation();
  // const { activated, currUser } = useSelector(getUser, shallowEqual);
  // const { authed: adminAuth } = useSelector(selectAdmin, shallowEqual);
  // const { chatsList } = useSelector(selectMessages, shallowEqual);
  const [openModal, setOpenModal] = useState(false);
  const [authMode, setAuthMode] = useState('');

  const { authed, user, navigate } = props;
  const { personal } = user;

  // useEffect(() => {
  //   function userUnload() {
  //     dispatch(changeUser({ online: false }));
  //   }

  //   if (!!currUser?.id) {
  //     window.addEventListener('unload', userUnload);
  //     //window.addEventListener('beforeunload', handleTabClosing)
  //     dispatch(getChatsDb(currUser.id));
  //     const obj = {
  //       online: true,
  //       visitDate: formatVisitDate(),
  //     };
  //     dispatch(changeUser(obj));
  //   }

  //   return () => {
  //     window.removeEventListener('unload', userUnload);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currUser.id]);

  // useEffect(() => {
  //   if (!!currUser?.id && chatsList.length > 0) dispatch(getAllMessagesDb(chatsList));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chatsList.length]);

  //const unreadChat = useMemo(() => chatsList?.find(({ read }) => !read), [chatsList]);
  //console.log(unreadChat);

  // const handleExit = () => {
  //   dispatch(changeUser({ online: false }));
  //   if (adminAuth) dispatch(adminLogout());
  //   dispatch(userLogout());
  //   setTimeout(() => {
  //     dispatch(clearPhotos());
  //     dispatch(clearMessages());
  //     dispatch(clearSympathies());
  //     dispatch(clearOrders());
  //     dispatch(clearFeedback());
  //   }, 600);
  // };

  // const handleLink = (e: React.MouseEvent) => {
  //   if (!activated) e.preventDefault();
  // };

  const handleOpenModal = (val: string) => () => {
    // signup  login
    setAuthMode(val);
    if (!openModal) setOpenModal(true);
    else setOpenModal(false);
  };

  const handleProfile = () => {
    if (!!user.id) navigate('/profile');
  };

  // const setBadgeChats = () => {
  //   const list = chatsList?.filter(({ read }) => !read) || [];
  //   //console.log(list.length);
  //   return list.length;
  // };

  // const setLinkClass = (path: string) => {
  //   const allowPath = path === '/questionnaires' || path === '/messages' || path === '/sympathies';

  //   if (pathname === path) return 'menu__link menu__link_active';
  //   else if (allowPath && !activated) return 'menu__link menu__link_hidden';
  //   else return 'menu__link';
  // };

  return (
    <>
      {!authed && !user?.id && (
        <div className="authInfoWrapper">
          <button
            className="userBtn actionBtn mainBtn"
            aria-label="open auth"
            onClick={handleOpenModal('login')}
          >
            {AccountBtn}
            {/* <AccountCircle fontSize="large" sx={{ width: '40px', height: '40px' }} /> */}
          </button>

          <AuthModal
            settings={{
              openModal: openModal,
              setOpenModal: setOpenModal,
              mode: authMode,
              setMode: setAuthMode,
            }}
          />
        </div>
      )}

      {authed && (
        <div className="authInfoWrapper userInfoWrapper">
          <button className="actionBtn mainBtn" aria-label="open profile" onClick={handleProfile}>
            <Avatar src="" className="avatar">
              {!!user.id ? personal?.name.slice(0, 1) : ''}
              {/* <NoPhotography fontSize="large" /> */}
            </Avatar>
          </button>
        </div>
      )}
    </>
  );
});

export default AuthMenu;
