/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { onValue, ref, get, child, set, update, remove } from 'firebase/database';
import {
  EmailAuthProvider,
  deleteUser,
  onAuthStateChanged,
  updateEmail,
  updatePassword,
  updateProfile,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { auth, db, login, logout, signUp } from '../../services/firebase';
import { AppDispatch } from '..';
import User from '@/entities/user';
import { Photo, Personal, User as UserType, Verification } from '@/utils/mainTypes';
import { formatRegistryDate, getCodeDb } from '@/utils/methods';

const initialState = {
  authed: false,
  activated: false,
  cookie: false,
  currUser: User,
  allUsers: [],
  viewedUser: {},
  request: {
    status: 0,
    error: null,
  },
  requestUser: {
    status: 0,
    error: null,
  },
  requestViewed: {
    status: 0,
    error: null,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authPending: state => {
      state.request.status = 1;
      state.request.error = null;
    },
    authFailure: (state, { payload }) => {
      state.request.error = payload;
      state.request.status = 3;
    },
    setAuth: (state, { payload }) => {
      state.authed = payload;
      state.request.status = 2;
    },
    userPending: state => {
      state.requestUser.status = 1;
      state.requestUser.error = null;
    },
    userFailure: (state, { payload }) => {
      state.requestUser.error = payload;
      state.requestUser.status = 3;
    },
    setUser: (state, { payload }) => {
      state.currUser = payload?.user;
      state.activated = payload?.user?.activated;
      //state.cookie = payload.cookie;
      state.requestUser.status = 2;
      state.requestUser.error = null;
    },
    setCookie: (state, { payload }) => {
      state.cookie = payload;
      state.request.status = 2;
      state.request.error = null;
    },
    setUpdatedUser: (state, { payload }) => {
      state.currUser.email = payload;
      state.requestUser.status = 2;
    },
    setUsers: (state, { payload }) => {
      state.allUsers = payload;
      state.requestUser.status = 2;
      state.requestUser.error = null;
    },
    pendingViewed: state => {
      state.requestViewed.status = 1;
    },
    failureViewed: (state, { payload }) => {
      state.requestViewed.status = 3;
      state.requestViewed.error = payload;
    },
    setViewedUser: (state, { payload }) => {
      state.viewedUser = payload;
      state.requestViewed.status = 2;
      state.requestViewed.error = null;
    },
    deletePending: state => {
      state.request.status = 10;
      state.request.error = null;
    },
    removeUser: state => {
      state.request.status = 11;
    },
    clearViewed: state => {
      state.viewedUser = {};
      state.requestViewed.status = 0;
      state.requestViewed.error = null;
    },
    clear: state => {
      state.authed = false;
      state.currUser = User;
      state.allUsers = [];
      state.viewedUser = {};
      state.request.status = 0;
      state.request.error = null;
      state.requestUser.status = 0;
      state.requestUser.error = null;
      state.requestViewed.status = 0;
      state.requestViewed.error = null;
    },
  },
});

const {
  authPending,
  authFailure,
  setAuth,
  setUser,
  setCookie,
  setUpdatedUser,
  userPending,
  userFailure,
  setUsers,
  deletePending,
  removeUser,
  pendingViewed,
  failureViewed,
  setViewedUser,
  clearViewed,
  clear,
} = authSlice.actions;
export default authSlice.reducer;

export const initUser = () => (dispatch: AppDispatch) => {
  dispatch(authPending());
  dispatch(userPending());

  onAuthStateChanged(
    auth,
    user => {
      if (!!user) {
        //console.log(user);
        dispatch(setAuth(true));

        const userDbRef = ref(db, `users/${user.uid}`);
        onValue(userDbRef, snapshot => {
          const data = snapshot.val();
          //const interests = Object.values(data?.interests || {});
          //console.log(data);
          const newUser = new User(
            data.id,
            user.uid,
            data.activated,
            user.email,
            data.avatar,
            data.personal,
            data.timeZone,
            data.verified,
            data.role,
            data.loggedIn || '',
          );
          dispatch(setUser({ user: newUser, cookie: data?.cookie }));
          //dispatch(setUser(newUser));
        });
      } else {
        dispatch(setAuth(false));
        dispatch(setUser({ user: User }));
        //dispatch(clear());
      }
    },
    err => {
      console.log(err);
      dispatch(userFailure('Ошибка: ' + getCodeDb(err.name)));
    },
  );
};

export const userLogin = (email: string, pass: string) => async (dispatch: AppDispatch) => {
  dispatch(authPending());

  try {
    await login(email, pass);
  } catch (err) {
    console.log(err);
    //console.log(err.message);
    dispatch(authFailure('Ошибка: ' + getCodeDb(err.code)));
  }
};

const updateProfileDb =
  (name: string): any =>
  () => {
    //dispatch(userPending());

    updateProfile(auth.currentUser, {
      displayName: name,
      photoURL: '',
    })
      .then(() => {
        // Profile updated!
      })
      .catch(err => {
        console.log('Ошибка: ' + getCodeDb(err.code));
      });
  };

export const userRegister =
  (email: string, passw: string, personal: Personal) => async (dispatch: AppDispatch) => {
    dispatch(authPending());

    //const userId = '1001';
    const randomId = Date.now().toString().slice(3, -2);
    const date = formatRegistryDate();
    const newPersonal = { ...personal };
    // delete newPersonal?.gender;
    // delete newPersonal?.searchGender;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timeZone = userTimeZone || 'Europe/Moscow';

    try {
      await signUp(email, passw);
      const id = auth.currentUser.uid;
      const userDbRef = ref(db, `users/${id}`);
      let userId = null;

      onValue(
        ref(db, 'currentId'),
        snapshot => {
          const data = snapshot.val();
          //userId = data.id + 1;
          userId = data + 1;
          //console.log(userId);

          if (userId) {
            set(ref(db, 'currentId'), userId);
            // set(ref(db, 'currentId'), {
            //   id: userId,
            // });
          }

          set(userDbRef, {
            id: userId || randomId,
            dbId: id,
            email: email,
            activated: false,
            avatar: '',
            // subscription: {
            //   paid: false,
            //   date: '',
            //   deadline: '',
            //   amountDays: 0,
            // },
            personal: newPersonal,
            //interests: {},
            // aboutme: {
            //   text: '',
            // },
            //gender: personal.gender,
            registryDate: date,
            timeZone,
            verified: 'none',
            role: 'user',
          });

          dispatch(updateProfileDb(personal.name));
        },
        {
          onlyOnce: true,
        },
      );
    } catch (err) {
      console.log(err);
      dispatch(authFailure('Ошибка: ' + getCodeDb(err.code)));
    }
  };

export const changeUserDb =
  (data: object, isLoad?: boolean): any =>
  (dispatch: AppDispatch) => {
    if (!isLoad) dispatch(userPending());

    const id = auth.currentUser.uid;
    const userDbRef = ref(db, `users/${id}`);
    update(userDbRef, data)
      .then(() => {
        //
      })
      .catch(err => {
        console.log(err);
        dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
      });
  };

// Profile

export const changeUserEmail = (email: string, passw: string) => async (dispatch: AppDispatch) => {
  dispatch(userPending());

  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(user.email, passw);

  try {
    await reauthenticateWithCredential(user, credential);
    //console.log(req);
    updateEmail(user, email)
      .then(() => {
        // Email updated!
        dispatch(setUpdatedUser(email));
        dispatch(changeUserDb({ email: email }));
      })
      .catch(err => {
        console.log(err);
        dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
      });
  } catch (err) {
    console.log(err);
    dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
  }
};

export const changeUserPassw =
  (passw: string, newPassw: string) => async (dispatch: AppDispatch) => {
    dispatch(userPending());
    //await dispatch(reauthenticateUser(passw));
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, passw);

    try {
      await reauthenticateWithCredential(user, credential);
      //console.log(req);
      updatePassword(user, newPassw)
        .then(() => {
          // Update successful.
          dispatch(setAuth(true));
        })
        .catch(err => {
          console.log(err);
          dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
        });
    } catch (err) {
      console.log(err);
      dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
    }
  };

export const resetUserPassw = (email: string) => (dispatch: AppDispatch) => {
  dispatch(authPending());

  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      dispatch(setAuth(false));
    })
    .catch(err => {
      console.log(err);
      dispatch(authFailure('Ошибка: ' + getCodeDb(err.code)));
    });
};

//

export const getUsers = () => (dispatch: AppDispatch) => {
  //dispatch(userPending());

  const dbRef = ref(db);
  get(child(dbRef, 'users'))
    .then(snapshot => {
      if (snapshot.exists()) {
        const data = Object.values(snapshot.val() || {});
        const filteredUsers = (data || []).filter((user: User) => user.role !== 'admin');
        dispatch(setUsers(filteredUsers));
      } else {
        console.log('No data available');
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const getViewedUser = (userId: string | number) => (dispatch: AppDispatch) => {
  dispatch(pendingViewed());

  onValue(
    ref(db, `users/${userId}`),
    snapshot => {
      // const data = Object.values(snapshot.val() || {});
      // const user = data.find(({ id }) => id === userId);
      const user = snapshot.val();
      if (!!user) dispatch(setViewedUser(user));
      else dispatch(failureViewed('Ошибка: Пользователь удален'));
    },
    err => {
      console.log(err);
      dispatch(failureViewed('Ошибка: ' + getCodeDb(err.name)));
    },
    {
      onlyOnce: true,
    },
  );
};

export const sendVerificationData =
  (data: FormData, verifData: Verification) => async (dispatch: AppDispatch) => {
    dispatch(userPending());
    const id = auth.currentUser.uid;
    const userDbRef = ref(db, `users/${id}`);
    const dbRefVerif = ref(db, `verifications/${verifData.id}`);

    try {
      const res = await fetch('/scripts/photo/upload_photo.php', {
        method: 'POST',
        //headers: { 'Content-Type': 'multipart/form-data' },
        body: data,
      });
      //console.log(res);

      if (res.status === 200) {
        const obj = {
          verified: 'pending',
        };

        update(userDbRef, obj)
          .then(() => {
            //
          })
          .catch(err => {
            console.log(err);
            dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
          });

        set(dbRefVerif, verifData)
          .then(() => {
            //
          })
          .catch(err => {
            console.log(err);
            dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
          });
      } else dispatch(userFailure('Фото не загрузилось. Проверьте тип и размер файла.'));
    } catch (err) {
      console.log(err);
      dispatch(userFailure('Ошибка: ' + err));
    }
  };

export const cookieAgreement = () => (dispatch: AppDispatch) => {
  dispatch(setCookie(true));
};

// export const reauthenticateUser = (passw: string) => async (dispatch: AppDispatch) => {
//   //dispatch(userPending());
//   const user = auth.currentUser;
//   const credential = EmailAuthProvider.credential(user.email, passw);

//   try {
//     const req = await reauthenticateWithCredential(user, credential);
//     console.log(req);
//     //return req;
//   } catch (err) {
//     console.log(err);
//     dispatch(userFailure(err.message.slice(16)));
//   }
// };

export const clearViewedUser = () => async (dispatch: AppDispatch) => {
  dispatch(clearViewed());
};

export const userLogout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(clear());
    await logout();
    // setTimeout(() => {
    //   dispatch(clear());
    // }, 600);
  } catch (err) {
    console.log(err);
    dispatch(authFailure('Ошибка: ' + err));
  }
};

// User del
export const userDeleteDb = (delUser: UserType, passw: string) => async (dispatch: AppDispatch) => {
  dispatch(deletePending());

  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(user.email, passw);
  const userDbRef = ref(db, `users/${user.uid}`);
  const photosDbRef = ref(db, `photos/${delUser.id}`);
  //const chatsDbRef = ref(db, `chats/${delUser.id}`);
  const deletedDbRef = ref(db, `deletedUsers/${delUser.id}`);

  try {
    await reauthenticateWithCredential(user, credential);
    //dispatch(userFailure('Данная функция временно недоступна'));

    onValue(
      photosDbRef,
      snapshot => {
        const data: Array<Photo> = Object.values(snapshot.val() || {});
        //console.log(data);
        if (!!data.length) {
          data.forEach(async ({ id }) => {
            const formData = new FormData();
            formData.append('photoId', id);
            await fetch('/scripts/photo/unlink_photo.php', {
              method: 'POST',
              //headers: { 'Content-Type': 'multipart/form-data' },
              body: formData,
            });
          });
        }

        const newUser = { ...delUser, deletedDate: formatRegistryDate() };
        set(deletedDbRef, newUser);

        remove(photosDbRef);
        //remove(chatsDbRef);
        remove(userDbRef);

        deleteUser(user)
          .then(() => {
            //console.log('User deleted');
            dispatch(removeUser());
            dispatch(clear());
          })
          .catch(err => {
            console.log(err);
            dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
          });
      },
      err => {
        console.log(err);
        dispatch(userFailure('Ошибка: ' + getCodeDb(err.name)));
      },
      {
        onlyOnce: true,
      },
    );
  } catch (err) {
    console.log(err);
    dispatch(userFailure('Ошибка: ' + err));
  }
};
