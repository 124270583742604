import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';

import { authLoading, authLoaded, authFailure } from '@/store/auth/selectors';
import { userLogin, userRegister, resetUserPassw } from '@/store/auth/slicer';
import { CloseBtn } from '@/assets/images/Icons';
import { mainColor, modalStyle } from '@/utils/constants';
import { checkReg } from '@/utils/methods';
import { PropsModal } from '../types';
import PasswField from '../common/PasswField';
import CitiesField from '../common/CitiesField';

//const mainColor = '#102239';

const AuthModal: React.FC<PropsModal> = React.memo((props: PropsModal) => {
  const dispatch = useDispatch();
  const loading = useSelector(authLoading, shallowEqual);
  const loaded = useSelector(authLoaded, shallowEqual);
  const userError = useSelector(authFailure, shallowEqual);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [passw, setPassw] = useState('');
  const [confirmPassw, setConfirmPassw] = useState('');
  const [age, setAge] = useState('');
  const [city, setCity] = useState('');
  // const [gender, setGender] = useState('');
  // const [searchGender, setSearchGender] = useState('');
  const [fieldError, setFieldError] = useState(false);
  const [dialogError, setDialogError] = useState('');
  const [citiesError, setCitiesError] = useState('');

  const { settings } = props;
  const { openModal, setOpenModal, mode, setMode } = settings;

  const handleClose = () => {
    setOpenModal(false);
    setName('');
    setEmail('');
    setPassw('');
    setConfirmPassw('');
    setAge('');
    setCity('');
    // setGender('');
    setFieldError(false);
    setDialogError('');
  };

  useEffect(() => {
    if (!!loaded) handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!!userError) setDialogError(userError);
    if (!!citiesError) setDialogError(citiesError);
  }, [userError, citiesError]);

  const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 50) setEmail(e.target.value);
  };

  const changeName = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 60) setName(e.target.value);
    setDialogError('');
  };

  const changeAge = (e: ChangeEvent<HTMLInputElement>) => {
    const event = e.nativeEvent as any;
    const reg = event.data?.match(/e/, 'i');
    if (e.target.value.length <= 2 && !reg) {
      setAge(e.target.value);
      setDialogError('');
    }
    //setDialogError('');
  };

  // const changeGender = (e: ChangeEvent<HTMLSelectElement>) => {
  //   const isMan = e.target.value === 'Man';
  //   const isWoman = e.target.value === 'Woman';

  //   if (isMan) {
  //     setGender(e.target.value);
  //     setSearchGender('Woman');
  //   }
  //   if (isWoman) {
  //     setGender(e.target.value);
  //     setSearchGender('Man');
  //   }
  //   setDialogError('');
  // };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const regs = checkReg([name, email, passw, confirmPassw, age, city]);

    if (regs) {
      //setFieldError(true);
      setDialogError('Введите необходимые данные корректно');
      return;
    } else setDialogError('');

    if (mode === 'signup' && passw !== confirmPassw) {
      setFieldError(true);
      setDialogError('Пароли не совпадают!');
      return;
    }

    if (mode === 'signup' && !!email && !!passw) {
      const obj = {
        name: !!name ? name : email,
        age: +age,
        city: city,
        //gender: gender,
      };
      //console.log(obj);
      //dispatch(userRegister(email, passw, obj));
    }

    if (mode === 'login' && !!email && !!passw) {
      dispatch(userLogin(email, passw));
    }

    if (mode === 'reset' && !!email) {
      dispatch(resetUserPassw(email));
    }
  };

  const handleSetMode = (value: string) => (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setFieldError(false);
    setDialogError('');
    setEmail('');
    setPassw('');
    setConfirmPassw('');
    setMode(value);
  };

  return (
    <Dialog open={openModal} onClose={handleClose} PaperProps={{ sx: modalStyle }}>
      <div className="mainDialog">
        <button className="closeBtn mainBtn" onClick={handleClose}>
          {CloseBtn}
        </button>

        {mode === 'signup' && <DialogTitle className="heading">Регистрация</DialogTitle>}
        {(mode === 'login' || mode === 'reset') && (
          <DialogTitle className="heading">Войдите в аккаунт</DialogTitle>
        )}

        {mode === 'signup' && (
          <DialogContent className="content">
            {/* <form id="auth-form" className="dialogForm" onSubmit={handleSubmit}>
              <input
                className="field"
                type="email"
                name="email"
                placeholder="Почта (E-mail)*"
                autoComplete="on"
                required
                value={email}
                onChange={changeEmail}
                pattern="(.{1,})@(.{1,}).([A-z]{2,8})"
                maxLength={50}
              />
              <PasswField
                labelId="auth-label-passw"
                label="Пароль*"
                value={passw}
                setPassw={setPassw}
                passwErr={fieldError}
                setPasswErr={setFieldError}
              />
              <PasswField
                labelId="auth-label-passw"
                label="Повторите пароль*"
                value={confirmPassw}
                setPassw={setConfirmPassw}
                passwErr={fieldError}
                setPasswErr={setFieldError}
              />
              <input
                className="field"
                type="name"
                name="name"
                placeholder="Ваше имя"
                autoComplete="on"
                //required
                value={name}
                onChange={changeName}
                pattern="[A-Za-zА-Яа-яЁё0-9\-_. s]+"
                maxLength={60}
              />
              <CitiesField
                label="Ваш город"
                required={false}
                city={city}
                setCity={setCity}
                setError={setCitiesError}
              />
              <input
                className="field"
                type="number"
                name="number"
                placeholder="Ваш возраст"
                //required
                value={age}
                onChange={changeAge}
                pattern="[0-9]+"
                maxLength={2}
                min="16"
                max="99"
              />

              <div className="policy">
                <Checkbox
                  className="checkbox"
                  id="policy"
                  required
                  defaultChecked
                  size="medium"
                  sx={{ color: mainColor, '&.Mui-checked': { color: mainColor } }}
                />
                <label className="label" htmlFor="policy">
                  Я принимаю условия
                  <a href="/user-policy" target="_blank" className="link">
                    Пользовательского соглашения
                  </a>
                </label>
              </div>

              <button className="submitBtn mainBtn" type="submit">
                Зарегистрироваться
              </button>
            </form> */}

            <p className="notSignup">Регистрация временно не доступна</p>

            <p className="login">
              У вас есть аккаунт?
              <a href="#" className="link" onClick={handleSetMode('login')}>
                Войти
              </a>
            </p>
          </DialogContent>
        )}

        {mode === 'login' && (
          <DialogContent className="content">
            <form id="auth-form" className="dialogForm" onSubmit={handleSubmit}>
              <input
                className="field"
                type="email"
                name="email"
                placeholder="Почта (E-mail)*"
                autoComplete="on"
                required
                value={email}
                onChange={changeEmail}
                pattern="(.{1,})@(.{1,}).([A-z]{2,8})"
                maxLength={50}
              />
              <PasswField
                labelId="auth-label-passw"
                label="Пароль*"
                value={passw}
                outlined={true}
                setPassw={setPassw}
                passwErr={fieldError}
                setPasswErr={setFieldError}
              />

              <a href="#" className="forgotPasswLink" onClick={handleSetMode('reset')}>
                Забыли пароль?
              </a>

              <button className="submitBtn mainBtn" type="submit">
                Войти
              </button>
            </form>

            <p className="login">
              У вас нет аккаута?
              <a href="#" className="link" onClick={handleSetMode('signup')}>
                Зарегистрироваться
              </a>
            </p>
          </DialogContent>
        )}

        {mode === 'reset' && (
          <DialogContent className="content">
            <form id="auth-form" className="dialogForm" onSubmit={handleSubmit}>
              <p className="resetPasswText">
                Введите почту от аккаунта. Вам будет отправлено письмо для сброса пароля
              </p>
              <input
                className="field"
                type="email"
                name="email"
                placeholder="Почта (E-mail)*"
                autoComplete="on"
                required
                value={email}
                onChange={changeEmail}
                pattern="(.{1,})@(.{1,}).([A-z]{2,8})"
                maxLength={50}
              />

              <button className="submitBtn mainBtn" type="submit">
                Отправить
              </button>
            </form>

            <p className="login">
              <a href="#" className="link" onClick={handleSetMode('login')}>
                Назад
              </a>
            </p>
          </DialogContent>
        )}

        {!!dialogError && <p className="dialogError">{dialogError}</p>}
        {loading && (
          <div className="dialogLoading">
            <CircularProgress className="progress" />
          </div>
        )}
      </div>
    </Dialog>
  );
});

export default AuthModal;
