import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Badge, Skeleton } from '@mui/material';
//
import { selectAdmin } from '@/store/admin/selectors';
import { initAdmin, adminLogout } from '@/store/admin/slicer';
//
import { selectAuth } from '@/store/auth/selectors';
import { initUser, changeUserDb } from '@/store/auth/slicer';
//import { getUser, userFailure } from '@/store/profile/selectors';
//import { cookieAgreement } from '@/store/profile/slicer';
import { getOrders } from '@/store/orders/selectors';
import { getCatalogDb, getOrder } from '@/store/orders/slicer';
import { selectFeedback } from '@/store/feedback/selectors';
import { addVisitDb } from '@/store/feedback/slicer';
//
import { menuLinks } from '@/utils/constants';
import { formatVisitDate, getDates, getMenuLinks, setHeaderScroll } from '@/utils/methods';
import {
  CartBtn,
  Logo,
  MenuBtn,
  CloseBtn,
  TgBtn,
  WaBtn,
  SelectionBtn,
  FavoriteBtn,
  PlaceBtn,
  ForumBtn,
  FeedbackBtn,
} from '@/assets/images/Icons';
import SearchParts from '@/components/SearchParts';
import FeedbackModal from '@/components/modals/Feedback';
import FeedbackWrapperModal from '@/components/modals/FeedbackWrapper';
import AuthMenu from './AuthMenu';
import DropMenu from './DropMenu';
import DropSnackbar from './Snackbar';

const settings = {
  waLink: 'https://wa.me/79950117725',
  tgLink: 'https://t.me/autoremarka',
  telLink: 'tel:+79950117725',
  tel: '8 995 011-77-25',
  place: 'Новосибирск',
};

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const actionsEl = useRef<HTMLDivElement>(null);
  const menuEl = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { authed: userAuth, cookie, currUser } = useSelector(selectAuth, shallowEqual);
  const { authed: adminAuth, currAdmin } = useSelector(selectAdmin, shallowEqual);
  const { cartList } = useSelector(getOrders, shallowEqual);
  const { visit } = useSelector(selectFeedback, shallowEqual);
  //const authError = useSelector(authFailure, shallowEqual);
  //
  const [errorSlicer, setErrorSlicer] = useState('');
  const [isShowMenu, setShowMenu] = useState(false);
  const [isShowMenuServ, setShowMenuServ] = useState(false);
  const [modalCall, setModalCall] = useState(false);
  const [modalFeedback, setModalFeedback] = useState(false);

  const regAdminPath = pathname.match(/\/admin\/[a-z]+/);

  // const showCatalogList = (show?: boolean) => {
  //   setShowMenuServ(show);
  //   const ls = document
  //     .querySelector('.menuMedia')
  //     .querySelector('.listServices') as HTMLDivElement;

  //   if (!show) {
  //     ls.style.opacity = '0';
  //     ls.style.height = '0';
  //     setTimeout(() => {
  //       ls.style.display = 'none';
  //     }, 100);
  //   } else {
  //     ls.style.display = 'block';
  //     setTimeout(() => {
  //       ls.style.opacity = '1';
  //       ls.style.height = '83px';
  //     }, 0);
  //   }
  // };

  useEffect(() => {
    document.addEventListener('scroll', setHeaderScroll);
    //setTextOpacity(['#header-opacity-0', '#footer-opacity-0']);
    dispatch(initUser());
    dispatch(getCatalogDb());

    if (visit?.date !== getDates().day + getDates().month) dispatch(addVisitDb());

    return () => {
      document.removeEventListener('scroll', setHeaderScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  useEffect(() => {
    const { loggedIn } = currUser;
    if (userAuth && currUser?.id && loggedIn && adminAuth) dispatch(initAdmin(currUser.id));
    if (!userAuth && adminAuth) dispatch(adminLogout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currUser?.id, currUser.loggedIn, userAuth, adminAuth]);

  // useEffect(() => {
  //   const { subscription } = currUser;
  //   if (!!currUser.id && subscription?.paid) {
  //     const obj = checkSubscription(subscription);
  //     const paid = obj.subscription.paid;
  //     const amountDays = obj.subscription.amountDays;
  //     //console.log(obj);
  //     if (paid !== subscription.paid || amountDays !== subscription.amountDays)
  //       dispatch(changeUser(obj));
  //   }

  //   if (!!currUser.id && refPay.current && subscription?.pending && !subscription?.paid) {
  //     dispatch(getOrder(subscription, currUser.id));
  //   }

  //   if (!!currUser.id && refPay.current) refPay.current = false;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currUser.subscription, pathname]);

  // User db
  useEffect(() => {
    // function userUnload() {
    //   dispatch(changeUserDb({ online: false }, true));
    // }
    const userUnload = () => {
      dispatch(changeUserDb({ online: false }, true));
    };

    if (!!currUser?.id) {
      window.addEventListener('unload', userUnload);
      //window.addEventListener('beforeunload', handleTabClosing)
      //dispatch(getChatsDb(currUser.id));
      const obj = {
        online: true,
        visitDate: formatVisitDate(),
      };

      dispatch(changeUserDb(obj, true));
    }

    return () => {
      window.removeEventListener('unload', userUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currUser?.id]);

  // Links
  useEffect(() => {
    document.documentElement.scrollTop = 0;

    const isAllowPath = pathname === '/profile' || pathname === '/user/lk';

    getMenuLinks(pathname, menuLinks);

    //if (allowPath && !userAuth (!userAuth || !currUser)) return navigate('/');

    if (isAllowPath && !userAuth) {
      navigate('/');
      setTimeout(() => {
        setErrorSlicer('');
      }, 600);
    }

    // if (pathname === '/' && !!userAuth) {
    //   // && !!currUser.id
    //   navigate('/profile');
    //   setTimeout(() => {
    //     document.body.style.overflow = 'auto';
    //   }, 100);
    // }

    // currUser?.id,
  }, [userAuth, pathname, navigate]);

  // useEffect(() => {
  //   const reg = pathname.match(/\/catalog/);
  //   // if (isShowMenu && !!reg) {
  //   //   showCatalogList(true);
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isShowMenu]);

  // Actions

  const handleAdminExit = () => {
    dispatch(adminLogout());
  };

  const handleToggleMenu = (open: boolean, path?: string) => () => {
    const menu = menuEl.current;
    //e: React.MouseEvent
    //const catalog = '/catalog';
    //&& path !== catalog

    if (!!menu) {
      menu.style.animation = 'menuClose .4s ease-in-out both';
      actionsEl.current.classList.remove('headerActionsShow-js');

      setTimeout(() => {
        setShowMenu(open);
        setShowMenuServ(false);
      }, 400);
      return;
    }

    // if (!!menu && path === catalog) {
    //   e.preventDefault();
    //   if (!isShowMenuServ) showCatalogList(true);
    //   else showCatalogList(false);
    // }

    if (!menu && !!open) {
      setShowMenu(open);
      actionsEl.current.classList.add('headerActionsShow-js');
    }
  };

  // const handleSetCookie = () => {
  //   //localStorage.setItem('cookie', 'true');
  //   // const obj = {
  //   //   cookie: true,
  //   // };
  //   // dispatch(changeUser(obj));
  //   dispatch(cookieAgreement());
  // };

  // const handleOpenModal = () => {
  //   dispatch(adminLogout());
  // };

  //

  const setLinkClass = (path: string) => {
    const newReg = new RegExp(path);
    const regPath = pathname.match(newReg);
    // const reg = pathname.match(/\/catalog/);
    // const cutLocPath = pathname.slice(0, 8);
    // const cutPath = path.slice(0, 8);

    if (pathname === path) return 'list__item list__item_active';
    else if (!!regPath) return 'list__item list__item_active';
    //else if (path === '/special-offers') return 'list__item list__item_promotion';
    //else if (cutLocPath === cutPath && !!reg) return 'list__item list__item_active';
    else return 'list__item';
  };

  // const setServiceClass = (path: string) => {
  //   const newReg = new RegExp(path);
  //   const regPath = pathname.match(newReg);
  //   //const regPath = `/services/${params?.name}`;

  //   if (pathname === path) return 'listServices__link listServices__link_active';
  //   else if (!!regPath) return 'listServices__link listServices__link_active';
  //   else return 'listServices__link';
  // };

  const returnMenuBtn = (showSpan?: boolean) => {
    return !isShowMenu ? (
      <button className="menuBtn mainBtn" aria-label="open menu" onClick={handleToggleMenu(true)}>
        {MenuBtn}
        {!!showSpan && <span>Каталог</span>}
      </button>
    ) : (
      <button className="menuBtn mainBtn" aria-label="close menu" onClick={handleToggleMenu(false)}>
        {CloseBtn}
        {!!showSpan && <span>Каталог</span>}
      </button>
    );
  };

  const createMenuEl = (name: string) => {
    return (
      <nav className={name}>
        {returnMenuBtn(true)}

        <ul className="list">
          {menuLinks.map((link, idx) => (
            <React.Fragment key={idx}>
              {link?.main && (
                <li
                  className={setLinkClass(link.path)}
                  style={link.path === '/special-offers' ? { fontWeight: 700 } : {}}
                >
                  <Link
                    to={link.path}
                    className="link"
                    onClick={handleToggleMenu(false, link.path)}
                  >
                    {link.name}
                  </Link>

                  {/* {link.path === '/catalog' && (
                    <div id="menu-services" className="servicesWrapper">
                      <ul className="listServices">
                        {menuServices.map((service, id) => (
                          <li key={id} className="listServices__item">
                            <Link
                              to={service.path}
                              className={setServiceClass(service.path)}
                              onClick={handleToggleMenu(false)}
                            >
                              {service.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )} */}
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </nav>
    );
  };

  return (
    <header id="header-scroll" className={!regAdminPath ? 'header' : ''}>
      {adminAuth && currUser?.loggedIn && (
        <div className="headerAdmin">
          <div className="container">
            <nav className="headerAdmin__nav">
              <a href="/" className={!regAdminPath ? 'link link_active' : 'link'}>
                Сайт
              </a>
              <a href="/admin/lk" className={regAdminPath ? 'link link_active' : 'link'}>
                Админ панель
              </a>
            </nav>

            <div className="headerAdmin__info">
              <div className="login">
                {!currAdmin.id ? (
                  <span className="mainSkeleton">
                    <Skeleton variant="text" height="21px" animation="wave" className="skeleton" />
                  </span>
                ) : (
                  <span>{currAdmin.name}</span>
                )}
              </div>
              <button className="exitBtn mainBtn mainBtnHover" onClick={handleAdminExit}>
                Выйти
              </button>
            </div>
          </div>
        </div>
      )}

      {!regAdminPath && (
        <div id="show-header" className="container">
          <div ref={actionsEl} className="headerActionsWrapper">
            <div className="headerActions">
              <a className="headerActions__item link cityBtn">
                {PlaceBtn}
                <span>{settings.place}</span>
              </a>
              <a href="/forum" className="headerActions__item link forumLink" target="_blank">
                {ForumBtn}
                <span>Авто Форум</span>
              </a>
              <a href="/cooperation" className="headerActions__item link coopLink" target="_blank">
                {ForumBtn}
                <span>Сотрудничество</span>
              </a>
            </div>

            <div className="headerActions">
              <button
                type="button"
                className="headerActions__item link callBtn mainBtn"
                aria-label="Call Back"
                onClick={() => setModalCall(true)}
              >
                {FeedbackBtn}
                <span>Обратный звонок</span>
              </button>

              <div className="headerActions__item feedback">
                <a
                  href={settings.waLink}
                  target="_blank"
                  rel="noreferrer"
                  className="linkBtn"
                  aria-label="whatsapp"
                >
                  {WaBtn}
                </a>
                <a
                  href={settings.tgLink}
                  target="_blank"
                  rel="noreferrer"
                  className="linkBtn"
                  aria-label="telegram"
                >
                  {TgBtn}
                </a>
                <a href={settings.telLink} className="linkTel link">
                  {settings.tel}
                </a>
              </div>
            </div>
          </div>

          <div className="headerInfo">
            <Link
              to="/"
              className="headerInfo__logo"
              aria-label="Logo - link home"
              onClick={handleToggleMenu(false)}
            >
              {Logo}
              {/* <img src="/images/Logo-AR.svg" alt="Logo AR" className="logoImage mainImage" /> */}
            </Link>

            {returnMenuBtn()}

            <div className="headerInfo__search">
              <SearchParts
                label="Введите номер или название запчасти"
                showData={true}
                toggleMenu={handleToggleMenu}
              />
            </div>

            <div className="headerInfo__actions">
              <button
                className="actionBtn mainBtn"
                aria-label="Open Feedback"
                onClick={() => setModalFeedback(true)}
              >
                {SelectionBtn}
              </button>

              <button
                className="favoriteBtn actionBtn mainBtn"
                aria-label="Open Favorites"
                //onClick={() => navigate('/cart')}
              >
                {FavoriteBtn}
              </button>

              <button
                className="cartBtn actionBtn mainBtn"
                aria-label="Open Cart"
                onClick={() => navigate('/cart')}
              >
                <Badge className="cartBadge" color="primary" badgeContent={cartList?.length}>
                  {CartBtn}
                </Badge>
              </button>

              <AuthMenu authed={userAuth} user={currUser} navigate={navigate} />
            </div>
          </div>

          {createMenuEl('headerMenu')}

          {isShowMenu && (
            <DropMenu menuEl={menuEl} list={menuLinks} toggleMenu={handleToggleMenu} />
          )}
        </div>
      )}

      {/* {errorSlicer && !!currUser?.id && (
        <Alert severity="error" className="mainAlert">
          Ошибка загрузки <br />
          <span className="textErr">{errorSlicer}</span>
        </Alert>
      )} */}

      <FeedbackModal
        settings={{
          openModal: modalCall,
          setOpenModal: setModalCall,
          mode: 'call',
        }}
      />

      <FeedbackWrapperModal
        settings={{
          openModal: modalFeedback,
          setOpenModal: setModalFeedback,
        }}
      />

      <DropSnackbar user={currUser} />

      {/* {!cookie && (
        <div className="mainPopup mainPopup_bottom">
          <div className="cookies">
            <p className="text">
              Мы используем cookie, чтобы сделать сайт лучше. Оставаясь на OneClickMate, вы
              принимаете
              <a href="/user-policy" target="_blank" className="link">
                пользовательское соглашение.
              </a>
            </p>
            <button className="btn" onClick={handleSetCookie}>
              Согласен
            </button>
          </div>
        </div>
      )} */}
    </header>
  );
};

export default Header;
