import React, { ChangeEvent, useState } from 'react';
import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PropsPasswField } from '../types';

const PasswField: React.FC<PropsPasswField> = React.memo((props: PropsPasswField) => {
  //
  const [showPassw, setShowPassw] = useState(false);
  const { label, value, setPassw, passwErr, setPasswErr } = props;

  const changePassw = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 50) setPassw(e.target.value);
    if (!!passwErr) setPasswErr(false);
  };

  const handleClickShowPassw = () => setShowPassw(show => !show);

  return (
    <FormControl variant="outlined" required fullWidth>
      {/* <InputLabel htmlFor={labelId}>{label}</InputLabel> */}
      <OutlinedInput
        //id={labelId}
        //label={label}
        //helperText="-_=.!?"
        className="field"
        type={showPassw ? 'text' : 'password'}
        name="password"
        placeholder={label}
        autoComplete="on"
        value={value}
        onChange={changePassw}
        error={passwErr}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              className="passwBtn"
              aria-label="toggle-password"
              onClick={handleClickShowPassw}
              //onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassw ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        //inputProps={{ pattern: '[A-Za-z0-9\-_=\+.,!?@#$%^&\(\)|]{6,50}', minLength: 6, maxLength: 50 }}
        inputProps={{ pattern: '[^а-яёА-ЯЁ S]+', minLength: 6, maxLength: 50 }}
      />
    </FormControl>
  );
});

export default PasswField;
