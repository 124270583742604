import { RootState } from '../index';

export function getOrders(state: RootState) {
  return state.orders;
}

//

export function orderPending(state: RootState) {
  return state.orders.request.status === 1;
}

export function ordersLoaded(state: RootState) {
  return state.orders.request.status === 2;
}

export function orderLoaded(state: RootState) {
  return state.orders.request.status === 4;
}

export function paymentLoaded(state: RootState) {
  return state.orders.requestPayment.status === 5;
}

export function orderFailure(state: RootState) {
  return state.orders.request.error;
}

//

export function catalogPending(state: RootState) {
  return state.orders.requestData.status === 1;
}

export function catalogLoaded(state: RootState) {
  return state.orders.requestData.status === 2;
}

export function catalogFailure(state: RootState) {
  return state.orders.requestData.error;
}

//

export function cartPending(state: RootState) {
  return state.orders.requestCart.status === 1;
}

export function cartLoaded(state: RootState) {
  return state.orders.requestCart.status === 2;
}

export function cartFailure(state: RootState) {
  return state.orders.requestCart.error;
}

// export function viewedPhotosPending(state: RootState) {
//   return state.orders.requestViewed.status === 1;
// }

// export function viewedPhotosFailure(state: RootState) {
//   return state.orders.requestViewed.error;
// }
