/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { onValue, ref, get, child, set, update, remove } from 'firebase/database';
import {
  EmailAuthProvider,
  deleteUser,
  onAuthStateChanged,
  updateEmail,
  updatePassword,
  updateProfile,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { auth, db, login, logout, signUp } from '../../services/firebase';
import { AppDispatch } from '..';
import User from '@/entities/user';
import { Interest, Photo, Personal, User as UserType, Verification } from '@/utils/mainTypes';
import { formatRegistryDate, getCodeDb } from '@/utils/methods';

const initialState = {
  authed: false,
  activated: false,
  cookie: false,
  currUser: User,
  allUsers: [],
  viewedUser: {},
  cityList: [],
  request: {
    status: 0,
    error: null,
  },
  requestData: {
    status: 0,
    error: null,
  },
  requestViewed: {
    status: 0,
    error: null,
  },
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setAuth: (state, { payload }) => {
      state.authed = payload;
      state.request.status = 2;
    },
    setUser: (state, { payload }) => {
      state.currUser = payload.user;
      state.activated = payload.user.activated;
      //state.cookie = payload.cookie;
      state.request.status = 2;
      state.request.error = null;
    },
    setCookie: (state, { payload }) => {
      state.cookie = payload;
      state.request.status = 2;
      state.request.error = null;
    },
    setUpdatedUser: (state, { payload }) => {
      state.currUser.email = payload;
      state.request.status = 2;
    },
    userPending: state => {
      state.request.status = 1;
      state.request.error = null;
    },
    userFailure: (state, { payload }) => {
      state.request.error = payload;
      state.request.status = 3;
    },
    setUsers: (state, { payload }) => {
      state.allUsers = payload;
      state.request.status = 2;
      state.request.error = null;
    },
    deletePending: state => {
      state.request.status = 10;
      state.request.error = null;
    },
    removeUser: state => {
      state.request.status = 11;
    },
    setViewedUser: (state, { payload }) => {
      state.viewedUser = payload;
      state.requestViewed.status = 2;
      state.requestViewed.error = null;
    },
    pendingViewed: state => {
      state.requestViewed.status = 1;
    },
    failureViewed: (state, { payload }) => {
      state.requestViewed.status = 3;
      state.requestViewed.error = payload;
    },
    pendingData: state => {
      state.requestData.status = 1;
    },
    failureData: (state, { payload }) => {
      state.requestData.status = 3;
      state.requestData.error = payload;
    },
    setCities: (state, { payload }) => {
      state.cityList = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    clearViewed: state => {
      state.viewedUser = {};
      state.requestViewed.status = 0;
      state.requestViewed.error = null;
    },
    clear: state => {
      state.authed = false;
      state.currUser = User;
      state.allUsers = [];
      state.viewedUser = {};
      state.request.status = 0;
      state.request.error = null;
      state.requestViewed.status = 0;
      state.requestViewed.error = null;
    },
  },
});

const {
  setAuth,
  setUser,
  setCookie,
  setUpdatedUser,
  userPending,
  userFailure,
  setUsers,
  deletePending,
  removeUser,
  pendingViewed,
  failureViewed,
  setViewedUser,
  pendingData,
  failureData,
  setCities,
  clearViewed,
  clear,
} = profileSlice.actions;
export default profileSlice.reducer;

// export const initUser = () => (dispatch: AppDispatch) => {
//   dispatch(userPending());

//   onAuthStateChanged(
//     auth,
//     user => {
//       if (user) {
//         //console.log(user);
//         dispatch(setAuth(true));
//         const userDbRef = ref(db, `users/${user.uid}`);
//         onValue(userDbRef, snapshot => {
//           const data = snapshot.val();
//           const interests = Object.values(data?.interests || {});
//           //console.log(data);
//           const newUser = new User(
//             data.id,
//             user.uid,
//             data.activated,
//             user.email,
//             data.avatar,
//             data.subscription,
//             data.personal,
//             interests,
//             data.aboutme,
//             data.gender,
//             data.searchGender,
//             data.timeZone,
//             data.verified,
//             data.loggedIn || '',
//           );
//           dispatch(setUser({ user: newUser, cookie: data?.cookie }));
//           //dispatch(setUser(newUser));
//         });
//       } else {
//         dispatch(setAuth(false));
//         //dispatch(clear());
//       }
//     },
//     err => {
//       console.log(err);
//       dispatch(userFailure('Ошибка: ' + getCodeDb(err.name)));
//     },
//   );
// };

export const changeUser = (data: object, isLoad?: boolean) => (dispatch: AppDispatch) => {
  if (!isLoad) dispatch(userPending());

  const id = auth.currentUser.uid;
  const userDbRef = ref(db, `users/${id}`);
  update(userDbRef, data)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
    });
};

export const addUserInterest = (data: Interest) => (dispatch: AppDispatch) => {
  dispatch(userPending());

  const id = auth.currentUser.uid;
  const dbRef = ref(db, `users/${id}/interests/${data.id}`);
  set(dbRef, data)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
    });
};

export const deleteUserInterest = (data: Interest) => (dispatch: AppDispatch) => {
  dispatch(userPending());

  const id = auth.currentUser.uid;
  const dbRef = ref(db, `users/${id}/interests/${data.id}`);
  remove(dbRef)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
    });
};

export const changeUserEmail = (email: string, passw: string) => async (dispatch: AppDispatch) => {
  dispatch(userPending());

  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(user.email, passw);

  try {
    await reauthenticateWithCredential(user, credential);
    //console.log(req);
    updateEmail(user, email)
      .then(() => {
        // Email updated!
        dispatch(setUpdatedUser(user.email));
        dispatch(changeUser({ login: email }));
      })
      .catch(err => {
        console.log(err);
        dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
      });
  } catch (err) {
    console.log(err);
    dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
  }
};

export const changeUserPassw =
  (passw: string, newPassw: string) => async (dispatch: AppDispatch) => {
    dispatch(userPending());
    //await dispatch(reauthenticateUser(passw));
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, passw);

    try {
      await reauthenticateWithCredential(user, credential);
      //console.log(req);
      updatePassword(user, newPassw)
        .then(() => {
          // Update successful.
          dispatch(setAuth(true));
        })
        .catch(err => {
          console.log(err);
          dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
        });
    } catch (err) {
      console.log(err);
      dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
    }
  };

export const resetUserPassw = (email: string) => (dispatch: AppDispatch) => {
  dispatch(userPending());

  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      dispatch(setAuth(false));
    })
    .catch(err => {
      console.log(err);
      dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
    });
};

export const getUsers = () => (dispatch: AppDispatch) => {
  //dispatch(userPending());

  const dbRef = ref(db);
  get(child(dbRef, 'users'))
    .then(snapshot => {
      if (snapshot.exists()) {
        const data = Object.values(snapshot.val() || {});
        const filteredUsers = (data || []).filter((user: User) => user.gender !== 'admin');
        dispatch(setUsers(filteredUsers));
      } else {
        console.log('No data available');
      }
    })
    .catch(error => {
      console.error(error);
    });
};

export const getViewedUser = (userId: string | number) => (dispatch: AppDispatch) => {
  dispatch(pendingViewed());

  onValue(
    ref(db, `users/${userId}`),
    snapshot => {
      // const data = Object.values(snapshot.val() || {});
      // const user = data.find(({ id }) => id === userId);
      const user = snapshot.val();
      if (!!user) dispatch(setViewedUser(user));
      else dispatch(failureViewed('Ошибка: Пользователь удален'));
    },
    err => {
      console.log(err);
      dispatch(failureViewed('Ошибка: ' + getCodeDb(err.name)));
    },
    {
      onlyOnce: true,
    },
  );
};

export const sendVerificationData =
  (data: FormData, verifData: Verification) => async (dispatch: AppDispatch) => {
    dispatch(userPending());
    const id = auth.currentUser.uid;
    const userDbRef = ref(db, `users/${id}`);
    const dbRefVerif = ref(db, `verifications/${verifData.id}`);

    try {
      const res = await fetch('/scripts/photo/upload_photo.php', {
        method: 'POST',
        //headers: { 'Content-Type': 'multipart/form-data' },
        body: data,
      });
      //console.log(res);

      if (res.status === 200) {
        const obj = {
          verified: 'pending',
        };

        update(userDbRef, obj)
          .then(() => {
            //
          })
          .catch(err => {
            console.log(err);
            dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
          });

        set(dbRefVerif, verifData)
          .then(() => {
            //
          })
          .catch(err => {
            console.log(err);
            dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
          });
      } else dispatch(userFailure('Фото не загрузилось. Проверьте тип и размер файла.'));
    } catch (err) {
      console.log(err);
      dispatch(userFailure('Ошибка: ' + err));
    }
  };

export const cookieAgreement = () => (dispatch: AppDispatch) => {
  dispatch(setCookie(true));
};

// export const reauthenticateUser = (passw: string) => async (dispatch: AppDispatch) => {
//   //dispatch(userPending());
//   const user = auth.currentUser;
//   const credential = EmailAuthProvider.credential(user.email, passw);

//   try {
//     const req = await reauthenticateWithCredential(user, credential);
//     console.log(req);
//     //return req;
//   } catch (err) {
//     console.log(err);
//     dispatch(userFailure(err.message.slice(16)));
//   }
// };

export const clearViewedUser = () => async (dispatch: AppDispatch) => {
  dispatch(clearViewed());
};

export const getCities = () => async (dispatch: AppDispatch) => {
  dispatch(pendingData());
  //const d = +Date.now().toString().slice(3, -2); //?nocache=123
  try {
    const res = await fetch('/scripts/russian-cities.json');
    const data = await res.json();
    //console.log(data);
    if (!!res.ok) dispatch(setCities(data.cities));
    else console.log(res.status);
  } catch (err) {
    console.log(err);
    dispatch(failureData(err));
  }
};

export const userLogout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(clear());
    await logout();
    // setTimeout(() => {
    //   dispatch(clear());
    // }, 600);
  } catch (err) {
    console.log(err);
    dispatch(userFailure('Ошибка: ' + err));
  }
};

export const userDeleteDb = (delUser: UserType, passw: string) => async (dispatch: AppDispatch) => {
  dispatch(deletePending());
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(user.email, passw);
  const userDbRef = ref(db, `users/${user.uid}`);
  const photosDbRef = ref(db, `photos/${delUser.id}`);
  const chatsDbRef = ref(db, `chats/${delUser.id}`);
  const likedDbRef = ref(db, `likedUsers/${delUser.id}`);
  const matchedDbRef = ref(db, `matchedUsers/${delUser.id}`);
  const guestedDbRef = ref(db, `guestedUsers/${delUser.id}`);
  const deletedDbRef = ref(db, `deletedUsers/${delUser.id}`);

  try {
    await reauthenticateWithCredential(user, credential);
    //dispatch(userFailure('Данная функция временно недоступна'));

    onValue(
      photosDbRef,
      snapshot => {
        const data: Array<Photo> = Object.values(snapshot.val() || {});
        //console.log(data);
        if (!!data.length) {
          data.forEach(async ({ id }) => {
            const formData = new FormData();
            formData.append('photoId', id);
            await fetch('/scripts/photo/unlink_photo.php', {
              method: 'POST',
              //headers: { 'Content-Type': 'multipart/form-data' },
              body: formData,
            });
          });
        }

        set(deletedDbRef, {
          id: delUser.id,
          dbId: user.uid,
          date: formatRegistryDate(),
          user: delUser,
        });

        remove(photosDbRef);
        remove(chatsDbRef);
        remove(likedDbRef);
        remove(matchedDbRef);
        remove(guestedDbRef);
        remove(userDbRef);

        deleteUser(user)
          .then(() => {
            //console.log('User deleted');
            dispatch(removeUser());
            dispatch(clear());
          })
          .catch(err => {
            console.log(err);
            dispatch(userFailure('Ошибка: ' + getCodeDb(err.code)));
          });
      },
      err => {
        console.log(err);
        dispatch(userFailure('Ошибка: ' + getCodeDb(err.name)));
      },
      {
        onlyOnce: true,
      },
    );
  } catch (err) {
    console.log(err);
    dispatch(userFailure('Ошибка: ' + err));
  }
};
