// Common

export const photosUrl = 'https://.ru/uploads/';

export const modalStyle = {
  m: '10px',
  maxWidth: 'initial',
  maxHeight: 'calc(100% - 20px)',
  background: '#FFF',
};

export const mainColor = '#102239';

// Menu
export const menuLinks = [
  {
    name: 'Главная',
    path: '/',
    title: 'AвтоРемарка — Подбор и покупка оригинальных запчастей для автомобилей',
    show: false,
    main: false,
    description:
      'Интернет-магазин автозапчастей "AвтоРемарка" — поиск и покупка оригинальных запчастей на автомобили по выгодным ценам',
  },
  // Catalog
  {
    name: 'Каталог',
    path: '/catalog',
    title: 'Каталог — AвтоРемарка',
    show: true,
    main: false,
    description:
      'Каталог интернет-магазина "AвтоРемарка" содержит в себе большой выбор запчастей на разные автомобили, переходите в нужный раздел и выбирайте подходящие автозапчасти',
    subMenu: [
      {
        name: 'Запчасти',
        path: '/catalog/parts',
        title: 'Запчасти | Каталог — AвтоРемарка',
        show: true,
        description: '',
        subMenu: [],
      },
      {
        name: 'Запчасти для ТО',
        path: '/catalog/car-inspection',
        title: 'Запчасти для ТО | Каталог — AвтоРемарка',
        show: true,
        description: '',
        subMenu: [],
      },
      {
        name: 'Фильтры',
        path: '/catalog/filters',
        title: 'Фильтры | Каталог — AвтоРемарка',
        show: true,
        description: '',
        subMenu: [],
      },
      {
        name: 'Масла',
        path: '/catalog/oils',
        title: 'Масла | Каталог — AвтоРемарка',
        show: true,
        description: '',
        subMenu: [],
      },
      {
        name: 'Тех. жидкости',
        path: '/catalog/technical',
        title: 'Тех. жидкости | Каталог — AвтоРемарка',
        show: true,
        description:
          'Подраздел каталога интернет-магазина "AвтоРемарка" Тех. жидкости, включает в себя большой выбор технических жидкостей на автомобили',
        subMenu: [],
      },
      {
        name: 'Аккумуляторы',
        path: '/catalog/akkumulyatory',
        title: 'Аккумуляторы | Каталог — AвтоРемарка',
        show: true,
        description:
          'Подраздел каталога интернет-магазина "AвтоРемарка" Аккумуляторы, включает в себя большой выбор аккумуляторов на автомобили',
        subMenu: [],
      },
      {
        name: 'Аксессуары',
        path: '/catalog/accessories',
        title: 'Аксессуары | Каталог — AвтоРемарка',
        show: true,
        description: '',
        subMenu: [],
      },
      {
        name: 'Автосигнализации',
        path: '/catalog/car-alarms',
        title: 'Автосигнализации | Каталог — AвтоРемарка',
        show: true,
        description:
          'Подраздел каталога интернет-магазина "AвтоРемарка" Автосигнализации, включает в себя большой выбор автосигнализаций',
        subMenu: [],
      },
      {
        name: 'Атрибутика',
        path: '/catalog/atributika',
        title: 'Атрибутика | Каталог — AвтоРемарка',
        show: true,
        description:
          'Подраздел каталога интернет-магазина "AвтоРемарка" Атрибутика, включает в себя большой выбор атрибутики для автомобилей',
        subMenu: [],
      },
      {
        name: 'Шины / Диски',
        path: '/catalog/shiny-diski',
        title: 'Шины / Диски | Каталог — AвтоРемарка',
        show: true,
        description:
          'Подраздел каталога интернет-магазина "AвтоРемарка" Шины и Диски, включает в себя большой выбор шин и диской на автомобили',
        subMenu: [],
      },
      {
        name: 'Прочее',
        path: '/catalog/other',
        title: 'Прочее | Каталог — AвтоРемарка',
        show: true,
        description:
          'В раздел Прочее интернет-магазина "AвтоРемарка", входит широкий спектр выбора автозапчастей',
        subMenu: [],
      },
    ],
  },
  {
    name: 'Запчасти',
    path: '/catalog/parts',
    title: 'Запчасти | Каталог — AвтоРемарка',
    show: false,
    main: true,
    description:
      'Подраздел каталога интернет-магазина "AвтоРемарка" Запчасти, включает в себя большой выбор автозапчастей',
    subMenu: [],
  },
  {
    name: 'Запчасти для ТО',
    path: '/catalog/car-inspection',
    title: 'Запчасти для ТО | Каталог — AвтоРемарка',
    show: false,
    main: true,
    description:
      'Подраздел каталога интернет-магазина "AвтоРемарка" Запчасти для ТО, включает в себя большой выбор автозапчастей для технического обслуживания',
    subMenu: [],
  },
  {
    name: 'Масла',
    path: '/catalog/oils',
    title: 'Масла | Каталог — AвтоРемарка',
    show: false,
    main: true,
    description:
      'Подраздел каталога интернет-магазина "AвтоРемарка" Масла, включает в себя большой выбор масел на автомобили',
    subMenu: [],
  },
  {
    name: 'Фильтры',
    path: '/catalog/filters',
    title: 'Фильтры | Каталог — AвтоРемарка',
    show: false,
    main: true,
    description:
      'Подраздел каталога интернет-магазина "AвтоРемарка" Фильтры, включает в себя большой выбор фильтров на автомобили',
    subMenu: [],
  },
  {
    name: 'Аксессуары',
    path: '/catalog/accessories',
    title: 'Аксессуары | Каталог — AвтоРемарка',
    show: false,
    main: true,
    description:
      'Подраздел каталога интернет-магазина "AвтоРемарка" Аксессуары, включает в себя большой выбор аксессуаров на автомобили',
    subMenu: [],
  },
  {
    name: 'Бренды',
    path: '/brands',
    title: 'Бренды — AвтоРемарка',
    show: true,
    main: true,
    description: '',
    subMenu: [],
  },
  //
  {
    name: 'Акции',
    path: '/special-offers',
    title: 'Акции — AвтоРемарка',
    show: true,
    main: true,
    description: '',
    subMenu: [],
  },
  {
    name: 'Контакты',
    path: '/contacts',
    title: 'Контакты — AвтоРемарка',
    show: true,
    main: false,
    description:
      'В данном разделе интернет-магазина "AвтоРемарка" вы найдете все необходимые наши контакты, а также сможете связаться со службой поддержки',
  },
  {
    name: 'Профиль',
    path: '/profile',
    show: false,
    main: false,
    title: 'Профиль — AвтоРемарка',
    description:
      'В разделе Профиль можно добавлять и изменять различные данные: основную информацию, интересы, раздел о себе, добавлять фотографии',
  },
  {
    name: 'Корзина',
    path: '/cart',
    show: false,
    main: false,
    title: 'Корзина — AвтоРемарка',
    description: 'Раздел Корзина для оформления заказов в интернет-магазине "AвтоРемарка"',
  },
  {
    name: 'Поиск',
    path: '/search',
    show: false,
    main: false,
    title: 'Поиск автозапчастей — AвтоРемарка',
    description: 'Поиск запчастей на автомобили в интернет-магазине "AвтоРемарка"',
  },
  {
    name: 'Форум',
    path: '/forum',
    show: false,
    main: false,
    title: 'Авто Форум — AвтоРемарка',
    description: '',
  },
  {
    name: 'Сотрудничество',
    path: '/cooperation',
    show: false,
    main: false,
    title: 'Сотрудничество с нами — AвтоРемарка',
    description: '',
  },
  //
  {
    name: 'Политика конфиденциальности',
    path: '/policy',
    title: 'Политика конфиденциальности — AвтоРемарка',
    show: false,
    main: false,
    description:
      'Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона. Сайт имеет право вносить изменения в настоящую Политику конфиденциальности',
  },
  {
    name: 'Пользовательское соглашение',
    path: '/user-policy',
    title: 'Пользовательское соглашение — AвтоРемарка',
    show: false,
    main: false,
    description:
      'Настоящее Пользовательское соглашение определяет правила и порядок использования Сайта и Услуг, права и обязанности Пользователей',
  },
];

// Brands

export const partsBrands = ['Changan', 'JAC', 'Mitsubishi'];

// Filters

interface FilterItemType {
  id: number;
  value: string;
}

const returnFilter = (id: number, category: string, list?: Array<FilterItemType>) => {
  return {
    id,
    category,
    checkboxType: true,
    priceType: true,
    brandType: true,
    name: '',
    title: 'Производители',
    brandList: list,
  };
};

const returnFilters = (id: number) => {
  let newId = id;
  //
  return [
    { id: ++newId, value: 'Changan' },
    { id: ++newId, value: 'JAC' },
    { id: ++newId, value: 'Mitsubishi' },
  ];
};

export const filterList = [
  returnFilter(1, 'parts', returnFilters(10)),
  returnFilter(2, 'car-inspection', returnFilters(20)),
  returnFilter(3, 'filters', [...returnFilters(110), { id: 1, value: 'Agama' }, { id: 2, value: 'Mann' }, { id: 3, value: 'Patron' }]),
  returnFilter(4, 'oils', returnFilters(30)),
  returnFilter(5, 'technical', returnFilters(40)),
  returnFilter(6, 'akkumulyatory', returnFilters(50)),
  returnFilter(7, 'accessories', returnFilters(60)),
  returnFilter(8, 'car-alarms', returnFilters(70)),
  returnFilter(9, 'atributika', returnFilters(80)),
  returnFilter(10, 'shiny-diski', returnFilters(90)),
  returnFilter(11, 'other', returnFilters(100)),
];

//
export const timeZoneList = [
  {
    name: 'МСК-1',
    utc: 'UTC+2',
    //time: '',
    value: 'Europe/Kaliningrad',
  },
  {
    name: 'МСК',
    utc: 'UTC+3',
    value: 'Europe/Moscow', // 'Europe/Simferopol', 'Europe/Kirov', 'Europe/Volgograd'
  },
  {
    name: 'МСК+1',
    utc: 'UTC+4',
    value: 'Europe/Samara', // 'Europe/Astrakhan', 'Europe/Saratov', 'Europe/Ulyanovsk'
  },
  {
    name: 'МСК+2',
    utc: 'UTC+5',
    value: 'Asia/Yekaterinburg',
  },
  {
    name: 'МСК+3',
    utc: 'UTC+6',
    value: 'Asia/Omsk',
  },
  {
    name: 'МСК+4',
    utc: 'UTC+7',
    value: 'Asia/Krasnoyarsk', // 'Asia/Novosibirsk', 'Asia/Barnaul', 'Asia/Tomsk', 'Asia/Novokuznetsk', ''
  },
  {
    name: 'МСК+5',
    utc: 'UTC+8',
    value: 'Asia/Irkutsk',
  },
  {
    name: 'МСК+6',
    utc: 'UTC+9',
    value: 'Asia/Yakutsk', // 'Asia/Chita', 'Asia/Khandyga'
  },
  {
    name: 'МСК+7',
    utc: 'UTC+10',
    value: 'Asia/Vladivostok', // 'Asia/Ust-Nera'
  },
  {
    name: 'МСК+8',
    utc: 'UTC+11',
    value: 'Asia/Magadan', // 'Asia/Sakhalin' 'Asia/Srednekolymsk'
  },
  {
    name: 'МСК+9',
    utc: 'UTC+12',
    value: 'Asia/Kamchatka', // 'Asia/Anadyr'
  },
];

//
export const helpList = [
  {
    title: '1. Чем вы отличаетесь от других сайтов знакомств?',
    text: 'Главное отличие OneClickMate от других подобных сайтов заключается в индивидуальном подходе к каждому пользователю. Стать участником OneClickMate возможно только при наличии серьёзного намерения найти себе пару. Мы не просто создали еще один ресурс для знакомств, но и тщательно продумали, как мы можем реально помочь вам встретить нужного и желанного человека. Это сайт, который мы создавали с любовью, продумывая каждую мелочь для вашего приятного пребывания на нём.',
  },
  {
    title: '2. Почему OneClickMate для людей старше 21 года?',
    text: 'OneClickMate создан для серьёзных знакомств. Мы искренне считаем, что большинство людей младше 21 года не готовы к серьёзным долгосрочным отношениям. Зачастую люди и более старшего возраста не полностью осознают, какого человека они желают видеть рядом с собой. Но чем старше человек, тем более чёткие цели он ставит перед собой. Поэтому мы готовы вкладывать свои усилия, знания, время и помогать тем, кто уже достиг возраста полного совершеннолетия.',
  },
  {
    title: '3. Я могу зарегистрироваться на OneClickMate, если я состою в браке?',
    text: 'Наш сайт создан для одиноких людей, ищущих себе пару. Если вы состоите в браке, то OneClickMate не для вас. Если, находясь в отношениях, вы регистрируетесь на сайте OneClickMate , то при обнаружении данного обстоятельства, ваша анкета будет удалена без права на восстановление. Мы уважаем ваш выбор и надеемся на уважение наших условий, а также на уважительное отношение к участникам сайта, которые приходят к нам с надеждой встретить надёжного спутника.',
  },
  {
    title: '4. Какие письма будут приходить мне после регистрации на сайте?',
    text: 'С момента регистрации на сайте OneClickMate вам придёт только три письма: письмо о подтверждении регистрации, письмо, если вы забыли пароль, и письмо с уведомлением об удалении анкеты, когда вы решите это сделать.',
  },
  {
    title: '5. Какие варианты оплаты услуг сайта OneClickMate существуют?',
    text: 'На сайте OneClickMate существует единый тариф для общего доступа ко всем основным услугам. При единовременной оплате за 3, 6 месяцев предусмотрена скидка.',
  },
  {
    title: ' 6. Что мне доступно после оплаты?',
    text: 'На сайте OneClickMate нет скрытых платежей, поэтому после оплаты услуг вам доступен весь основной функционал: переписка, неограниченное количество отправленных и полученных сообщений, лайков, неограниченный просмотр анкет.',
  },
  {
    title: '7. Легко ли отменить оплату? ',
    text: 'Отменить оплату услуг сайта OneClickMate очень легко, перейдя в раздел Настройки — Оплата.',
  },
  {
    title: '8. Как я могу удалить свою анкету с сайта? ',
    text: 'Если вы решили, что наши услуги вам больше не требуются, то удалить анкету можно в один клик в разделе Настройки.',
  },
  {
    title: ' 9. Я смогу выбирать любой город? ',
    text: 'Да, на сайте OneClickMate доступен выбор любого города без ограничений.',
  },
  {
    title: '10. Почему фото не прошло модерацию?',
    text: 'Ваши фотографии могут не пройти проверку в том случае, если они не соответствуют правилам сайта OneClickMate. Прежде чем загружать свои фотографии, советуем вам внимательно ознакомиться с нашими требованиями.',
  },
  {
    title: ' 11. Как лучше заполнить профиль?',
    text: 'Если вы искренне желаете встретить близкого вам по интересам человека, то заполняя свой профиль, важно писать только о себе, своих интересах, увлечениях и ценностях. Напишите самое важное и интересное, то, что, по вашему мнению, характеризует вас лучше всего и отличает от других людей. Так ваши шансы на удачу возрастут.',
  },
  {
    title: '12. Почему мой профиль не виден другим участникам, когда я вношу в него изменения?',
    text: 'Модераторы сайта OneClickMate проверяют любые изменения, которые вы вносите в фотографии и самопрезентацию. У нас серьёзные требования к оформлению вашей анкеты, и мы не можем допустить, чтобы они нарушались. Как только внесённые вами изменения проходят проверку, ваша анкета становиться снова видна остальным участникам.',
  },
  {
    title: ' 13. Почему в сообщениях нельзя делиться ссылками?',
    text: 'Мы заботимся о вашей веб-безопасности, и поэтому делиться ссылками в чате OneClickMate нельзя. Кроме перехода на полезные ресурсы, ссылки могут перебрасывать вас на мошеннические сайты. Например, вы можете оказаться быть подписанными на какую-нибудь услугу, потеряв таким образом свои деньги. Также ссылки на неизвестные ресурсы могут ввести вас в заблуждение с целью получить конфиденциальную информацию или активировать вредоносную программу на вашем устройстве.',
  },
];
