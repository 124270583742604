/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { onValue, ref, remove, set, update } from 'firebase/database';
import { auth, db } from '../../services/firebase';
import { AppDispatch } from '..';
import Admin from '@/entities/admin';
import { Order, Photo, Product, User, Verification } from '@/utils/mainTypes';
import { formatRegistryDate, getCodeDb, getDates } from '@/utils/methods';

const initialState = {
  authed: false,
  currAdmin: Admin,
  allAdmins: [],
  allUsers: [],
  viewUser: {},
  deletedUsers: [],
  //
  orders: [],
  catalog: [],
  lastTables: [],
  checkedLink: null,
  catalogUpdated: null,
  //
  supportMsgs: [],
  visits: {},
  verifications: [],
  //
  request: {
    status: 0,
    error: null,
  },
  requestData: {
    status: 0,
    error: null,
  },
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAuth: (state, { payload }) => {
      state.authed = payload;
      state.request.status = 2;
    },
    setAdmin: (state, { payload }) => {
      state.currAdmin = payload;
      state.request.status = 2;
      state.request.error = null;
    },
    adminPending: state => {
      state.request.status = 1;
      state.request.error = null;
    },
    adminSuccess: state => {
      state.request.status = 2;
      state.request.error = null;
    },
    adminFailure: (state, { payload }) => {
      state.request.error = payload;
      state.request.status = 3;
    },
    dataPending: state => {
      state.requestData.status = 1;
      state.requestData.error = null;
    },
    dataFailure: (state, { payload }) => {
      state.requestData.error = payload;
      state.requestData.status = 3;
    },
    setUsers: (state, { payload }) => {
      state.allUsers = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setViewUser: (state, { payload }) => {
      state.viewUser = payload;
      state.request.status = 2;
      state.request.error = null;
      // state.requestData.status = 2;
      // state.requestData.error = null;
    },
    setAdmins: (state, { payload }) => {
      state.allAdmins = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setDeletedUsers: (state, { payload }) => {
      state.deletedUsers = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    // Catalog
    setCatalog: (state, { payload }) => {
      state.catalog = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setLastTable: (state, { payload }) => {
      state.lastTables = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setCheckedLink: (state, { payload }) => {
      state.checkedLink = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setCatalogUpdated: (state, { payload }) => {
      state.catalogUpdated = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setOrders: (state, { payload }) => {
      state.orders = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    //
    setSupportMsgs: (state, { payload }) => {
      state.supportMsgs = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setVisits: (state, { payload }) => {
      state.visits[payload.key] = payload.value;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    setVerifications: (state, { payload }) => {
      state.verifications = payload;
      state.requestData.status = 2;
      state.requestData.error = null;
    },
    clear: state => {
      state.authed = false;
      state.currAdmin = Admin;
      state.allUsers = [];
      state.viewUser = null;
      state.allAdmins = [];
      state.deletedUsers = [];
      state.orders = [];
      state.catalog = [];
      state.lastTables = [];
      state.checkedLink = null;
      state.catalogUpdated = null;
      state.supportMsgs = [];
      state.visits = {};
      state.verifications = [];
      state.request.status = 0;
      state.request.error = null;
      state.requestData.status = 0;
      state.requestData.error = null;
    },
  },
});

const {
  setAuth,
  setAdmin,
  adminPending,
  adminSuccess,
  adminFailure,
  setUsers,
  setViewUser,
  dataPending,
  dataFailure,
  setAdmins,
  setDeletedUsers,
  setOrders,
  setCatalog,
  setLastTable,
  setCheckedLink,
  setCatalogUpdated,
  setSupportMsgs,
  setVisits,
  setVerifications,
  clear,
} = adminSlice.actions;
export default adminSlice.reducer;

// Admins
export const initAdmin = (id: number) => (dispatch: AppDispatch) => {
  dispatch(adminPending());

  if (auth) {
    //const authId = auth.currentUser.uid;
    const dbRef = ref(db, `admins/${id}`);
    onValue(
      dbRef,
      snapshot => {
        const data = snapshot.val();
        //console.log(data);
        if (!!data) {
          const newAdmin = new Admin(data.id, data.dbId, data.name, data.role, data.visitDate);
          //dispatch(setAuth(true));
          dispatch(setAdmin(newAdmin));
        } else dispatch(setAuth(false));
      },
      err => {
        console.log(err);
        dispatch(setAuth(false));
        dispatch(adminFailure('Ошибка: ' + err.message.slice(35)));
      },
    );
  }
};

export const adminLogin = (id: number, name: string, passw: string) => (dispatch: AppDispatch) => {
  dispatch(adminPending());

  if (auth) {
    const authId = auth.currentUser.uid;
    const dbRef = ref(db, `admins/${id}`);
    const dbRefUser = ref(db, `users/${authId}`);
    const obj = {
      loggedIn: true,
    };

    onValue(
      dbRef,
      snapshot => {
        const data = snapshot.val();
        //console.log(data);
        if (!data) dispatch(adminFailure('Такого пользователя не существует!'));
        else {
          if (data.name === name && data.passw === passw) {
            dispatch(setAuth(true));
            update(dbRefUser, obj)
              .then(() => {
                //
              })
              .catch(err => {
                console.log(err);
                dispatch(adminFailure(getCodeDb(err.code)));
              });
            //
          } else dispatch(adminFailure('Ошибка: Неверный логин или пароль'));
        }
      },
      err => {
        console.log(err);
        dispatch(adminFailure('Ошибка: ' + getCodeDb(err.name)));
      },
      {
        onlyOnce: true,
      },
    );
  }
};

export const adminRegister = (admin: Admin) => (dispatch: AppDispatch) => {
  dispatch(adminPending());
  // const d = new Date();
  // const deadline = new Date(d.setMonth(d.getMonth() + amount));
  // const subscription = {
  //   //pending: true,
  //   paid: true,
  //   variant: amount,
  //   date: formatRegistryDate(),
  //   deadline,
  //   amountDays: 0,
  //   orderId: 0,
  // };

  onValue(
    ref(db, 'users'),
    snapshot => {
      const users: Array<User> = Object.values(snapshot.val() || {});
      const user = users.find(({ id }) => +id === admin.id) as User;

      if (!!user) {
        const newAdmin = { ...admin, dbId: user.dbId, role: admin.role };
        const obj = {
          role: 'admin',
          //searchGender: 'admin',
          //subscription,
        };
        const dbRefAdmin = ref(db, `admins/${admin.id}`);
        const dbRefUser = ref(db, `users/${user.dbId}`);

        set(dbRefAdmin, newAdmin)
          .then(() => {
            dispatch(adminSuccess());
          })
          .catch(err => {
            console.log(err);
            dispatch(adminFailure(getCodeDb(err.code)));
          });

        update(dbRefUser, obj)
          .then(() => {
            //
          })
          .catch(err => {
            console.log(err);
            dispatch(adminFailure(getCodeDb(err.code)));
          });
      }
    },
    err => {
      console.log(err);
      dispatch(adminFailure('Ошибка: ' + getCodeDb(err.name)));
    },
    {
      onlyOnce: true,
    },
  );
};

export const updateAdmin = (admin: Admin) => (dispatch: AppDispatch) => {
  //dispatch(adminPending());

  const dbRef = ref(db, `admins/${admin.id}`);
  update(dbRef, admin)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(adminFailure(getCodeDb(err.code)));
    });
};

export const removeAdminDb = (admin: Admin) => (dispatch: AppDispatch) => {
  //ispatch(dataPending());

  const dbRefAdmin = ref(db, `admins/${admin.id}`);
  const dbRefUser = ref(db, `users/${admin.dbId}`);
  const obj = {
    //gender: admin.gender,
    role: 'user',
    loggedIn: false,
  };

  update(dbRefUser, obj)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(adminFailure(getCodeDb(err.code)));
    });

  remove(dbRefAdmin)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.code)));
    });
};

export const getAdmins = () => (dispatch: AppDispatch) => {
  dispatch(dataPending());

  //const authId = auth.currentUser.uid;
  const dbRef = ref(db, 'admins');
  onValue(
    dbRef,
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      //console.log(data);
      dispatch(setAdmins(data));
    },
    err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.name)));
    },
  );
};

// Users

export const getUsers = () => (dispatch: AppDispatch) => {
  dispatch(dataPending());

  const dbRef = ref(db, 'users');
  onValue(
    dbRef,
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      //console.log(data);
      dispatch(setUsers(data));
    },
    err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.name)));
    },
  );

  // get(child(dbRef, 'users'))
  //   .then(snapshot => {
  //     if (snapshot.exists()) {
  //       const data = Object.values(snapshot.val() || {});
  //       //console.log(data);
  //       dispatch(setUsers(data));
  //     } else {
  //       console.log('No data available');
  //     }
  //   })
  //   .catch(error => {
  //     console.error(error);
  //   });
};

export const getViewUserDb = (userDbId: string) => (dispatch: AppDispatch) => {
  dispatch(adminPending());

  const dbRef = ref(db, `users/${userDbId}`);

  onValue(
    dbRef,
    snapshot => {
      const user = snapshot.val() || {};
      //console.log(user);
      dispatch(setViewUser(user));
    },
    err => {
      console.log(err);
      dispatch(adminFailure('Ошибка: ' + err.message.slice(16)));
    },
    {
      onlyOnce: true,
    },
  );
};

export const getDeletedUsers = () => (dispatch: AppDispatch) => {
  dispatch(dataPending());

  const dbRef = ref(db, 'deletedUsers');
  onValue(
    dbRef,
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      //console.log(data);
      dispatch(setDeletedUsers(data));
    },
    err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.name)));
    },
  );
};

export const updateUserDb = (userDbId: string, user: object) => (dispatch: AppDispatch) => {
  //dispatch(dataPending());

  const dbRef = ref(db, `users/${userDbId}`);

  update(dbRef, user)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.code)));
    });

  // onValue(
  //   dbRef,
  //   snapshot => {
  //     const data = Object.values(snapshot.val() || {});
  //     const userFind = data.find(({ id }) => id === userId);
  //     if (!!userFind) {
  //       update(dbRef, user)
  //         .then(() => {
  //           //
  //         })
  //         .catch(err => {
  //           console.log(err);
  //           dispatch(adminFailure(err.message));
  //         });
  //     }
  //   },
  //   err => {
  //     console.log(err);
  //     dispatch(adminFailure('Ошибка: ' + err.message.slice(16)));
  //   },
  //   {
  //     onlyOnce: true,
  //   },
  // );
};

export const removeUserDb = (delUser: User) => (dispatch: AppDispatch) => {
  //dispatch(deletePending());
  const userDbRef = ref(db, `users/${delUser.dbId}`);
  const photosDbRef = ref(db, `photos/${delUser.id}`);
  //const chatsDbRef = ref(db, `chats/${delUser.id}`);
  const deletedDbRef = ref(db, `deletedUsers/${delUser.id}`);

  onValue(
    photosDbRef,
    snapshot => {
      const data: Array<Photo> = Object.values(snapshot.val() || {});
      //console.log(data);
      if (!!data.length) {
        data.forEach(async ({ id }) => {
          const formData = new FormData();
          formData.append('photoId', id);

          await fetch('/scripts/photo/unlink_photo.php', {
            method: 'POST',
            //headers: { 'Content-Type': 'multipart/form-data' },
            body: formData,
          });
        });
      }

      const newUser = { ...delUser, deletedDate: formatRegistryDate() };
      set(deletedDbRef, newUser);
      // set(deletedDbRef, {
      //   id: delUser.id,
      //   dbId: delUser.dbId,
      //   date: formatRegistryDate(),
      //   user: delUser,
      // });

      remove(photosDbRef);
      //remove(chatsDbRef);
      remove(userDbRef);
    },
    err => {
      console.log(err);
      dispatch(dataFailure('Ошибка: ' + getCodeDb(err.name)));
    },
    {
      onlyOnce: true,
    },
  );
};

// Catalog

export const initCatalogDb = () => async (dispatch: AppDispatch) => {
  dispatch(dataPending());

  try {
    const res = await fetch('/scripts/parts/pages/list.json');
    const data = await res.json();
    const list = data?.list || [];
    list as Array<Product>;
    //console.log(data);

    if (res.ok && !!list.length) {
      list.forEach((product: Product) => {
        //if (product.id <= 110) {
        //console.log(product);
        const dbRef = ref(db, `catalog/${product.code}`);
        set(dbRef, product)
          .then(() => {
            //dispatch(adminSuccess());
          })
          .catch(err => {
            console.log(err);
            dispatch(dataFailure(getCodeDb(err.code)));
          });
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const getCatalog = () => (dispatch: AppDispatch) => {
  dispatch(dataPending());

  const dbRef = ref(db, 'catalog');
  onValue(
    dbRef,
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      //console.log(data);
      dispatch(setCatalog(data));
    },
    err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.name)));
    },
  );
};

export const updateProductDb = (product: Product) => (dispatch: AppDispatch) => {
  //dispatch(dataPending());

  const dbRef = ref(db, `catalog/${product.code}`);
  update(dbRef, product)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.code)));
    });
};

export const getLastTable = () => (dispatch: AppDispatch) => {
  //dispatch(dataPending());

  const dbRef = ref(db, 'loadTables');
  onValue(
    dbRef,
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      //console.log(data);
      dispatch(setLastTable(data));
    },
    err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.name)));
    },
  );
};

export const updateLastTable = (names: Array<string>) => (dispatch: AppDispatch) => {
  //dispatch(dataPending());

  const dbRef = ref(db, 'loadTables');
  set(dbRef, names)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.code)));
    });
};

export const checkLinkTable = (link: string) => async (dispatch: AppDispatch) => {
  //dispatch(dataPending());
  dispatch(setCheckedLink(null));

  try {
    const res = await fetch(link);
    //console.log(res);

    if (res.ok) {
      dispatch(setCheckedLink(true));
    } else {
      dispatch(setCheckedLink(false));
      dispatch(dataFailure('Такой таблицы не существует!'));
    }
    //
  } catch (err) {
    console.log(err);
    dispatch(dataFailure(getCodeDb(err)));
  }
};

export const getCatalogUpdated = () => (dispatch: AppDispatch) => {
  //dispatch(dataPending());

  const dbRef = ref(db, 'catalogUpdated');
  onValue(
    dbRef,
    snapshot => {
      const data = snapshot.val() || {};
      //console.log(data);
      dispatch(setCatalogUpdated(data));
    },
    err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.name)));
    },
  );
};

export const updateCatalogDb = (name: string) => async (dispatch: AppDispatch) => {
  dispatch(dataPending());

  const dbRefCatalog = ref(db, 'catalog');
  const dbRefUpdated = ref(db, 'catalogUpdated');

  try {
    const req = await fetch(`/scripts/parts/prices/${name}.json`);
    const res = await req.json();
    const list = (res?.list || []) as Array<Product>;
    //list as Array<Product>;
    //console.log(res);

    if (req.ok && !!list.length) {
      onValue(
        dbRefCatalog,
        snapshot => {
          const data = Object.values(snapshot.val() || {});
          //console.log(data);
          //dispatch(setCatalog(data));
          data.forEach((product: Product) => {
            const finded = list.find(({ code }) => product.code === code);

            if (!!finded) {
              const dbRef = ref(db, `catalog/${finded.code}`);
              const newProduct = { ...product, price: finded.price, quantity: finded.quantity };

              update(dbRef, newProduct)
                .then(() => {
                  //dispatch(adminSuccess());
                })
                .catch(err => {
                  console.log(err);
                  dispatch(adminFailure(getCodeDb(err.code)));
                });
            }
          });

          const obj = {
            date: formatRegistryDate(),
            nameTable: name,
            uploadedTable: false,
          };

          set(dbRefUpdated, obj)
            .then(() => {
              //
            })
            .catch(err => {
              console.log(err);
              dispatch(dataFailure('Ошибка: ' + getCodeDb(err.code)));
            });
        },
        err => {
          console.log(err);
          dispatch(dataFailure(getCodeDb(err.name)));
        },
        {
          onlyOnce: true,
        },
      );
    } else dispatch(dataFailure('Ошибка. Попробуйте ещё раз!'));
    //
  } catch (err) {
    console.log(err);
    dispatch(dataFailure(err));
  }
};

export const uploadTableData = (data: FormData, name?: string) => async (dispatch: AppDispatch) => {
  dispatch(dataPending());
  const dbRef = ref(db, 'catalogUpdated');

  update(dbRef, { uploadedTable: false });

  try {
    const res = await fetch('/scripts/parts/upload_table.php', {
      method: 'POST',
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: data,
    });
    //console.log(res);

    if (res.status === 200) {
      const obj = {
        date: '',
        uploadedTable: true,
        nameTable: '',
      };

      set(dbRef, obj)
        .then(() => {
          //
        })
        .catch(err => {
          console.log(err);
          dispatch(dataFailure('Ошибка: ' + getCodeDb(err.code)));
        });
      //
    } else dispatch(dataFailure('Таблица не загрузилась. Проверьте тип и размер файла'));
    //
  } catch (err) {
    console.log(err);
    dispatch(dataFailure('Ошибка: ' + err));
  }
};

// Orders

export const getOrdersDb = () => (dispatch: AppDispatch) => {
  dispatch(dataPending());

  const dbRef = ref(db, 'orders');
  onValue(
    dbRef,
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      //console.log(data);
      dispatch(setOrders(data));
    },
    err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.name)));
    },
  );
};

export const updateOrderDb = (order: Order) => (dispatch: AppDispatch) => {
  //dispatch(dataPending());

  const dbRef = ref(db, `orders/${order.id}`);
  update(dbRef, order)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.code)));
    });
};

export const removeOrderDb = (orderId: number) => (dispatch: AppDispatch) => {
  //ispatch(dataPending());

  const dbRef = ref(db, `orders/${orderId}`);
  remove(dbRef)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.code)));
    });
};

//

export const getSupportMsgs = () => (dispatch: AppDispatch) => {
  dispatch(dataPending());

  const dbRef = ref(db, 'supportMsgs');
  onValue(
    dbRef,
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      //console.log(data);
      dispatch(setSupportMsgs(data));
    },
    err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.name)));
    },
  );
};

export const updateSupportMsg = (msgId: number, msg: object) => (dispatch: AppDispatch) => {
  //dispatch(dataPending());

  const dbRef = ref(db, `supportMsgs/${msgId}`);
  update(dbRef, msg)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.code)));
    });
};

export const removeSupportMsg = (msgId: number) => (dispatch: AppDispatch) => {
  //ispatch(dataPending());

  const dbRefMsg = ref(db, `supportMsgs/${msgId}`);
  remove(dbRefMsg)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.code)));
    });
};

export const getVisitsDb = () => (dispatch: AppDispatch) => {
  dispatch(dataPending());

  const year = getDates().year;
  const month = getDates().month;
  const day = getDates().day;
  const dbRefdDay = ref(db, `visits/${year}/${month}/${day}`);
  const dbRefMonth = ref(db, `visits/${year}/${month}`);
  const dbRefYear = ref(db, `visits/${year}`);

  onValue(
    dbRefdDay,
    snapshot => {
      const amount = snapshot.val() || 0;
      const obj = {
        key: 'day',
        value: amount,
      };

      dispatch(setVisits(obj));
    },
    err => {
      console.log(err);
      dispatch(dataFailure('Ошибка: ' + getCodeDb(err.name)));
    },
  );

  onValue(
    dbRefMonth,
    snapshot => {
      const list = Object.values(snapshot.val() || {});
      //console.log(list);
      const amount = list.reduce((partialSum, a) => +partialSum + +a, 0);
      const obj = {
        key: 'month',
        value: amount,
      };

      dispatch(setVisits(obj));
    },
    err => {
      console.log(err);
      dispatch(dataFailure('Ошибка: ' + getCodeDb(err.name)));
    },
  );

  onValue(
    dbRefYear,
    snapshot => {
      let amount = 0;
      const listMonth = Object.values(snapshot.val() || {});
      //console.log(listMonth);
      listMonth.forEach(item => {
        const listItem = Object.values(item || {});
        //console.log(listItem);
        amount = amount + listItem.reduce((partialSum, a) => +partialSum + +a, 0);
      });
      const obj = {
        key: 'year',
        value: amount,
      };

      dispatch(setVisits(obj));
    },
    err => {
      console.log(err);
      dispatch(dataFailure('Ошибка: ' + getCodeDb(err.name)));
    },
  );
};

export const getVerificationsDb = () => (dispatch: AppDispatch) => {
  dispatch(dataPending());

  const dbRef = ref(db, 'verifications');
  onValue(
    dbRef,
    snapshot => {
      const data = Object.values(snapshot.val() || {});
      //console.log(data);
      dispatch(setVerifications(data));
    },
    err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.name)));
    },
  );
};

export const updateVerificationDb = (verif: Verification) => (dispatch: AppDispatch) => {
  //dispatch(dataPending());

  const dbRef = ref(db, `verifications/${verif.id}`);

  update(dbRef, verif)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(dataFailure(getCodeDb(err.code)));
    });
};

export const adminLogout = () => (dispatch: AppDispatch) => {
  const authId = auth.currentUser?.uid;
  const dbRefUser = ref(db, `users/${authId}`);
  const obj = {
    loggedIn: false,
  };

  update(dbRefUser, obj)
    .then(() => {
      //
    })
    .catch(err => {
      console.log(err);
      dispatch(adminFailure(getCodeDb(err.code)));
    });

  dispatch(clear());
};
