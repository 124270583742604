import { RootState } from '../index';

export function selectFeedback(state: RootState) {
  return state.feedback;
}

export function feedbackPending(state: RootState) {
  return state.feedback.request.status === 1;
}

export function feedbackLoaded(state: RootState) {
  return state.feedback.request.status === 2;
}

export function feedbackFailure(state: RootState) {
  return state.feedback.request.error;
}

//

export function selectionPending(state: RootState) {
  return state.feedback.requestSelection.status === 1;
}

export function selectionLoaded(state: RootState) {
  return state.feedback.requestSelection.status === 2;
}

export function selectionFailure(state: RootState) {
  return state.feedback.requestSelection.error;
}
