import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Logo, TgBtn, VkBtn } from '@/assets/images/Icons';

const settings = {
  waLink: 'https://wa.me/79950117725',
  tgLink: 'https://t.me/autoremarka',
  vkLink: 'https://t.me/autoremarka',
};

const Footer: React.FC = () => {
  const { pathname } = useLocation();

  const regAdminPath = pathname.match(/\/admin\/[a-z]+/);
  const currentYear = new Date().getFullYear();

  return (
    <>
      {!regAdminPath ? (
        <footer className="footer">
          <div className="container">
            <div className="footerInfo">
              <div className="footerInfo__logo">
                {Logo}
                <p className="text">
                  АвтоРемарка
                  <span>Автозапчасти</span>
                </p>
              </div>

              <p className="footerInfo__copy">
                Интернет-магазин запчастей
                <br />
                Автозапчасти в наличии и под заказ
                <br />
                &copy; {currentYear} AUTOREMARKA.RU
              </p>

              <p className="footerInfo__policy">
                Продолжая работать с этим сайтом, вы соглашаетесь со сбором и обработкой
                обезличенных персональных данных в автоматическом режиме и{' '}
                <a href="/user-policy" target="_blank" className="link">
                  Пользовательским соглашением
                </a>
              </p>
            </div>

            <nav className="footerMenu">
              <p className="footerMenu__title">Покупателям</p>

              <ul className="footerMenu__list footerMenu__list_buyers">
                <li className="listItem">
                  <a href="#" className="link">
                    Как сделать заказ
                  </a>
                </li>
                <li className="listItem">
                  <a href="#" className="link">
                    Оплата
                  </a>
                </li>
                <li className="listItem">
                  <a href="#" className="link">
                    Возврат
                  </a>
                </li>
                <li className="listItem">
                  <a href="#" className="link">
                    Доставка
                  </a>
                </li>
                <li className="listItem">
                  <a href="#" className="link">
                    Программа лояльности
                  </a>
                </li>
                <li className="listItem">
                  <a href="#" className="link">
                    Гарантийная политика
                  </a>
                </li>
                <li className="listItem">
                  <a href="#" className="link">
                    Правовая информация
                  </a>
                </li>
              </ul>
            </nav>

            <nav className="footerMenu">
              <p className="footerMenu__title">Партнерам</p>

              <ul className="footerMenu__list">
                <li className="listItem">
                  <a href="#" className="link">
                    Поставщикам
                  </a>
                </li>
                <li className="listItem">
                  <a href="#" className="link">
                    Оптовым покупателям
                  </a>
                </li>
                <li className="listItem">
                  <a href="#" className="link">
                    Реклама на сайте
                  </a>
                </li>
              </ul>
            </nav>

            <nav className="footerMenu">
              <p className="footerMenu__title">АвтоРемарка</p>

              <ul className="footerMenu__list">
                <li className="listItem">
                  <a href="/about" className="link">
                    О компании
                  </a>
                </li>
                <li className="listItem">
                  <a href="/news" className="link">
                    Новости
                  </a>
                </li>
                <li className="listItem">
                  <a href="/contacts" className="link">
                    Контакты
                  </a>
                </li>
              </ul>
            </nav>

            <div className="joinUs">
              <p className="joinUs__title">Присоединяйтесь к нам</p>

              <div className="joinUs__btns">
                <a
                  href={settings.tgLink}
                  target="_blank"
                  rel="noreferrer"
                  className="linkBtn link"
                  aria-label="telegram"
                >
                  {TgBtn}
                </a>
                <a
                  href={settings.vkLink}
                  target="_blank"
                  rel="noreferrer"
                  className="linkBtn link"
                  aria-label="telegram"
                >
                  {VkBtn}
                </a>
              </div>

              <p className="joinUs__title">Принимаем к оплате</p>

              <div className="payments" aria-label="Bank card list">
                <span
                  className="payments__item payments__item_mastercard"
                  aria-label="mastercard"
                />
                <span className="payments__item payments__item_visa" aria-label="visa" />
                <span className="payments__item payments__item_mir" aria-label="mir" />
              </div>
            </div>

            <a href="https://beffect.ru" target="_blank" className="bELogo" rel="noreferrer">
              <p className="text">При поддержке Beffect</p>
            </a>
          </div>
        </footer>
      ) : (
        <footer style={{ display: 'none' }}></footer>
      )}
    </>
  );
};

export default Footer;
