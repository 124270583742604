import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
//
import { getUser } from '@/store/profile/selectors';
import { feedbackPending, feedbackLoaded, feedbackFailure } from '@/store/feedback/selectors';
import { sendFeedbackData, sendSupportMsg } from '@/store/feedback/slicer';
import { checkReg, formatVisitDate } from '@/utils/methods';
import { mainColor, modalStyle } from '@/utils/constants';
import { CloseBtn } from '@/assets/images/Icons';
import { PropsModal } from '../types';

const FeedbackModal: React.FC<PropsModal> = React.memo((props: PropsModal) => {
  const dispatch = useDispatch();
  const { currUser } = useSelector(getUser, shallowEqual);
  const loading = useSelector(feedbackPending, shallowEqual);
  const loaded = useSelector(feedbackLoaded, shallowEqual);
  const feedbackError = useSelector(feedbackFailure, shallowEqual);
  //
  const [select, setSelect] = useState('');
  const [data, setData] = useState({ name: '', email: '', phone: '+7', text: '', company: '' });
  const [checkboxWa, setCheckboxWa] = useState(false);
  const [checkboxTg, setCheckboxTg] = useState(false);
  const [dialogError, setDialogError] = useState('');

  const { settings } = props;
  const { openModal, setOpenModal, mode } = settings;

  //console.log(mode);

  const isModeCall = mode === 'call';
  const isModeFeedback = mode === 'feedback';

  // useEffect(() => {
  //   if (openModal && currUser?.id) {
  //     setEmail(currUser?.email || '');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [openModal]);

  const handleClose = () => {
    setOpenModal(false);
    setDialogError('');

    setTimeout(() => {
      setSelect('');
      setData({ name: '', email: '', phone: '+7', text: '', company: '' });
      setCheckboxWa(false);
      setCheckboxTg(false);
    }, 500);
  };

  useEffect(() => {
    if (!!loaded) handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!!feedbackError) setDialogError(feedbackError);
  }, [feedbackError]);

  // const changeThanks = (e: ChangeEvent) => {
  //   if (e.target.value.length <= 60) setSelect(e.target.value);
  //   setDialogError('');
  // };

  // const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.value.length <= 50) setEmail(e.target.value);
  // };

  const changeData = (key: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const obj = { ...data };
    obj[key] = e.target.value;

    setData(obj);
  };

  const changeCheckWa = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckboxWa(e.target.checked);
    setCheckboxTg(false);
  };

  const changeCheckTg = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckboxTg(e.target.checked);
    setCheckboxWa(false);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const { name, phone, email, text, company } = data;
    const regs = checkReg([name, phone, email, text, company]);
    //const regsMsg = checkRegMsg(text);
    const formData = new FormData();

    if (!!regs) {
      setDialogError('Введите необходимые данные корректно');
      return;
    } else setDialogError('');

    formData.append('wa', String(checkboxWa));
    formData.append('tg', String(checkboxTg));

    if (!regs) {
      if (isModeCall) formData.append('title', '[Заявка на звонок]');
      else formData.append('title', '[Заявка на обратную связь]');
      //formData.append('theme', select);
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('company', company);
      formData.append('text', text);

      dispatch(sendFeedbackData(formData));
      //console.log(formData);
    }

    // if (!!currUser?.id && !regs) {
    //   const obj = {
    //     //id: 1,
    //     theme: select,
    //     date: formatVisitDate(),
    //     text: text,
    //     user: currUser,
    //     done: false,
    //   };

    //   dispatch(sendSupportMsg(obj));
    // }
  };

  return (
    <Dialog open={openModal} onClose={handleClose} PaperProps={{ sx: modalStyle }}>
      <div className="mainDialog feedbackDialog">
        <button className="closeBtn mainBtn" onClick={handleClose}>
          {CloseBtn}
        </button>

        {isModeCall && <DialogTitle className="heading">Заявка на звонок</DialogTitle>}
        {isModeFeedback && <DialogTitle className="heading">Заявка на обратную связь</DialogTitle>}

        <DialogContent className="content">
          <form id="feedback-form" method="POST" className="dialogForm" onSubmit={handleSubmit}>
            {/* <select
              className="field select"
              //id=""
              //placeholder=""
              name="gender"
              required
              value={select}
              onChange={e => setSelect(e.target.value)}
            >
              <option value="">--Выберите тему обращения--</option>
              <option value="Благодарность">Благодарность нашей команде</option>
              <option value="Жалоба">Жалоба</option>
              <option value="Предложение">Предложение</option>
              <option value="Вопрос">Вопрос</option>
              <option value="Другое">Другое</option>
            </select> */}

            <input
              className="field"
              type="name"
              name="name"
              placeholder="Как к вам обращаться*"
              required
              autoComplete="on"
              value={data.name}
              onChange={changeData('name')}
              pattern="^[A-Za-zА-Яа-яЁё0-9\-_. s]+"
              maxLength={60}
            />

            <input
              className="field"
              type="tel"
              name="tel"
              placeholder="+7 (777) 777-77-77*"
              required
              autoComplete="on"
              value={data.phone}
              onChange={changeData('phone')}
              pattern="(^8|7|\+7)\s?[\(]{0,1}[0-9]{3}[\)]{0,1}\s?\d{3}[\-\s?]{0,1}\d{2}[\-\s?]{0,1}\d{2}"
              maxLength={20}
            />

            {isModeFeedback && (
              <>
                <input
                  className="field"
                  type="text"
                  name="company"
                  placeholder="Название компании"
                  //required
                  autoComplete="on"
                  value={data.company}
                  onChange={changeData('company')}
                  pattern="^[A-Za-zА-Яа-яЁё0-9\-_. s]+"
                  maxLength={100}
                />

                <input
                  className="field"
                  type="email"
                  name="email"
                  placeholder="Ваш E-mail"
                  //required
                  autoComplete="on"
                  value={data.email}
                  onChange={changeData('email')}
                  pattern="(.{1,})@(.{1,}).([A-z]{2,8})"
                  maxLength={50}
                />

                <textarea
                  id="message"
                  className="field textarea"
                  name="message"
                  placeholder="Ваш вопрос"
                  //required
                  rows={5}
                  maxLength={500}
                  spellCheck="true"
                  value={data.text}
                  onChange={changeData('text')}
                />
              </>
            )}

            <div className="policy">
              {/* <Checkbox
                className="checkbox"
                id="policy"
                required
                defaultChecked
                size="medium"
                sx={{ color: mainColor, '&.Mui-checked': { color: mainColor } }}
                // htmlFor="policy"
              /> */}
              <p className="label">
                Отправляя, Вы разрешаете обработку персональных данных и соглашаетесь c
                <a href="/policy" target="_blank" className="link">
                  Политикой конфиденциальности
                </a>
              </p>
            </div>

            <div className="writeMe">
              <span className="text">
                <Checkbox
                  className="checkbox"
                  id="writeme-wa"
                  //required
                  size="medium"
                  value={checkboxWa}
                  onChange={changeCheckWa}
                  checked={checkboxWa}
                  sx={{ color: mainColor, '&.Mui-checked': { color: mainColor } }}
                />
                <label className="label" htmlFor="writeme-wa">
                  Напишите мне в WhatsApp
                </label>
              </span>

              <span className="text">
                <Checkbox
                  className="checkbox"
                  id="writeme-tg"
                  //required
                  size="medium"
                  value={checkboxTg}
                  onChange={changeCheckTg}
                  checked={checkboxTg}
                  sx={{ color: mainColor, '&.Mui-checked': { color: mainColor } }}
                />
                <label className="label" htmlFor="writeme-tg">
                  Напишите мне в Telegram
                </label>
              </span>
            </div>

            <button className="submitBtn mainBtn" type="submit">
              Отправить
            </button>
          </form>
        </DialogContent>

        {!!dialogError && <p className="dialogError">{dialogError}</p>}
        {loading && (
          <div className="dialogLoading">
            <CircularProgress className="progress" />
          </div>
        )}
      </div>
    </Dialog>
  );
});

export default FeedbackModal;
