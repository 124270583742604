import { MenuLink, Subscription } from './mainTypes';

const mskTimeZone = 'Europe/Moscow';

// Get

export const getElement = (elId: string) => {
  const element = document.querySelector(elId);
  return {
    el: element,
    top: element?.getBoundingClientRect().top,
    height: element?.offsetHeight,
  };
};

export const getMenuLinks = (path: string, list: Array<MenuLink>) => {
  list.forEach((link: MenuLink) => {
    const newReg = new RegExp(link.path);
    const regPath = path.match(newReg);
    //console.log(regPath);
    const headEl = document.head.children as any;

    if (path === link.path) {
      document.title = link.title;
      headEl.description.content = link.description;
    } else if (!!regPath) {
      const subMenuFinded = link?.subMenu?.find(subLink => subLink.path === path);
      //console.log(subMenuFinded);
      if (!!subMenuFinded) {
        document.title = subMenuFinded.title;
        headEl.description.content = subMenuFinded.description;
      }
    } else return;
  });
};

export const getProductPrice = (price: string) => {
  return +price.replaceAll(' ', '').replace('₽', '');
};

export const getProductStatus = (status: string) => {
  if (status === 'pending') return 'В обработке';
  if (status === 'ordered') return 'Заказан';
  if (status === 'sent') return 'Отправлен';
  if (status === 'ready') return 'Готов к выдаче';
  if (status === 'received') return 'Получен';
  if (status === 'canceled') return 'Отменен';
};

// Set

export const setHeaderScroll = () => {
  const scrollTop = document.documentElement.scrollTop;
  const headerTopEl = getElement('#header-scroll').el;
  //const scrollHeight = headerTopEl.scrollHeight;
  //console.log(scrollHeight);
  //130
  if (scrollTop > 10 && window.innerWidth > 667) headerTopEl.classList.add('headerScroll');
  if (scrollTop > 150 && window.innerWidth > 667) headerTopEl.classList.add('headerScrollPosition');

  if (scrollTop === 0 && window.innerWidth > 667) {
    headerTopEl.classList.remove('headerScroll');
    headerTopEl.classList.remove('headerScrollPosition');
  }
};

export const addRating = (num: number, ratingEl) => {
  ratingEl.querySelectorAll('.btn').forEach((el, idx) => {
    el.classList.add('hiddenBtn');
    if (num >= idx + 1) el.classList.add('btn_active');
  });
};

export const removeRating = (num: number, ratingEl) => {
  ratingEl.querySelectorAll('.btn').forEach((el, idx) => {
    el.classList.remove('hiddenBtn');
    el.classList.remove('btn_active');
    //if (num >= idx + 1) el.classList.remove('btn_active');
  });
};

// Reg

export function checkReg(arr: Array<string>) {
  const regAll = arr.filter(val => val.match(/^\s+$/));
  //console.log(regAll);
  if (!!regAll.length) return true;
}

export function checkRegMsg(msg: string) {
  const obj = {
    str: null,
    hack: null,
    link: null,
  };
  obj.str = msg.match(/^\s+$/);
  obj.hack = msg.match(/<\w+>/);
  obj.link = msg.match(/(https:)|(http:)|([A-Za-z0-9а-яёА-ЯЁ\-]{1,}\.[A-Za-zа-яёА-ЯЁ]{2,4})/); //.{1,}\.\D{2,4}
  //console.log(obj);
  return obj;
}

// Formatter

export const productPriceFormat = (price: number) => {
  //const percent = 20;
  //const newPrice = price + (price * percent) / 100;
  //console.log(newPrice);
  let priceStr = String(parseInt(price));
  priceStr = priceStr.replace(/^(\d)(\d)(\d)(\d)$/, '$1 $2$3$4');
  priceStr = priceStr.replace(/^(\d)(\d)(\d)(\d)(\d)$/, '$1$2 $3$4$5');
  priceStr = priceStr.replace(/^(\d)(\d)(\d)(\d)(\d)(\d)$/, '$1$2$3 $4$5$6');
  priceStr = priceStr.replace(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/, '$1 $2$3$4 $5$6$7');
  priceStr = priceStr + ' ₽';

  return priceStr;
};

export const priceFormat = (price: number) => {
  let priceStr = String(parseInt(price));
  priceStr = priceStr.replace(/^(\d)(\d)(\d)(\d)$/, '$1 $2$3$4');
  priceStr = priceStr.replace(/^(\d)(\d)(\d)(\d)(\d)$/, '$1$2 $3$4$5');
  priceStr = priceStr.replace(/^(\d)(\d)(\d)(\d)(\d)(\d)$/, '$1$2$3 $4$5$6');
  priceStr = priceStr.replace(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/, '$1 $2$3$4 $5$6$7');
  priceStr = priceStr.replace(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/, '$1$2 $3$4$5 $6$7$7');

  return priceStr;
};

export const formatRegistryDate = () => {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const date = new Date();
  return new Intl.DateTimeFormat('ru-RU', options).format(date).toString();
};

export const formatMsgDate = (timeZone: string) => {
  const options = { timeZone: timeZone, day: '2-digit', month: '2-digit', year: 'numeric' };
  const date = new Date();
  return new Intl.DateTimeFormat('ru-RU', options).format(date).toString();
};

export const formatMsgTime = (timeZone: string) => {
  const options = { timeZone: timeZone, hour: 'numeric', minute: '2-digit' };
  const date = new Date();
  return new Intl.DateTimeFormat('ru-RU', options).format(date).toString();
};

export const formatVisitDate = () => {
  const options = {
    timeZone: mskTimeZone,
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  };
  const date = new Date();
  return new Intl.DateTimeFormat('ru-RU', options).format(date).toString();
};

export const formatVisitAdmin = () => {
  const options = {
    timeZone: mskTimeZone,
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
  };
  const date = new Date();
  return new Intl.DateTimeFormat('ru-RU', options).format(date).toString();
};

// Get

export const getPrevMonth = () => {
  const date = new Date();
  new Date(date.setMonth(date.getMonth() - 1));
  //console.log(date);
  return date;
};

export const getRegistryDate = (date: string) => {
  const newDate = date?.replace(/(\d{1,2}).(\d{1,2}).(\d{4})/, '$3-$2-$1') || '';
  //console.log(new Date(newDate));
  return new Date(newDate);
};

export const genMsgId = (value: string) => {
  const d = new Date().toLocaleDateString().replace(/\./g, '').slice(1);
  return `${value}-${d}${Date.now().toString().slice(4, -2)}`;
};

export const genId = (value: string, adminId?: number) => {
  let n = 1;
  if (!!adminId) n = 0;
  // const d = Date.now().toString().slice(0, 1);
  // if (+d === 0 || +d === 1) n + 1;
  return `${value}-${n}${Date.now().toString()}`;
};

export const checkSubscription = (data: Subscription) => {
  let newPaid = data?.paid;
  let newDate = data?.date;
  let newDeadline = data?.deadline || '';
  const date = new Date();
  const dateDeadline = new Date(data?.deadline);
  const day = 1000 * 60 * 60 * 24;
  const diff = Math.floor(dateDeadline?.getTime() - date.getTime());
  //const diff = new Date(deadline.getTime() - date.getTime());
  let days = Math.floor(diff / day);
  //console.log(days);
  //console.log(deadline < date);
  if (dateDeadline < date) {
    newPaid = false;
    newDate = '';
    newDeadline = '';
    days = 0;
  }

  return {
    subscription: {
      orderId: data.orderId,
      variant: data.variant,
      paid: newPaid,
      date: newDate,
      deadline: newDeadline,
      amountDays: days,
    },
  };
};

export const getVisitDate = () => {
  return new Date().toJSON().slice(0, 10);
};

export const getDates = () => {
  const optionsYear = { timeZone: mskTimeZone, year: 'numeric' };
  const optionsMonth = { timeZone: mskTimeZone, month: '2-digit' };
  const optionsDay = { timeZone: mskTimeZone, day: '2-digit' };
  const date = new Date();

  return {
    year: new Intl.DateTimeFormat('ru-RU', optionsYear).format(date).toString(),
    month: new Intl.DateTimeFormat('ru-RU', optionsMonth).format(date).toString(),
    day: new Intl.DateTimeFormat('ru-RU', optionsDay).format(date).toString(),
  };
};

export const getCodeDb = (code: string) => {
  if (code === 'auth/wrong-password') return 'Неверный пароль';
  else if (code === 'auth/invalid-login-credentials') return 'Неверные логин или пароль';
  else if (code === 'auth/user-not-found') return 'Пользователь не найден';
  else if (code === 'auth/email-already-in-use') return 'Данная почта уже используется';
  else if (code === 'Error') return 'Внутренняя ошибка сервера';
  else if (code === 'PERMISSION_DENIED') return 'ДОСТУП ЗАПРЕЩЕН';
  else return code;
};

// const createArr = (amount: number) => {
//   const arr = [];
//   for (let i = 1; i <= amount; i++) {
//     arr.push(i);
//   }
//   return arr;
// };
