import React, { useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import { getOrders, catalogPending, catalogLoaded, catalogFailure } from '@/store/orders/selectors';
//import { getCatalogDb } from '@/store/orders/slicer';
import { SearchBtn } from '@/assets/images/Icons';
import { Product } from '@/utils/mainTypes';
import { PropsSearchParts } from './types';

const SearchParts: React.FC<PropsSearchParts> = React.memo((props: PropsSearchParts) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchRef = useRef<HTMLInputElement>(null);
  const loading = useSelector(catalogPending, shallowEqual);
  const errorLoad = useSelector(catalogFailure, shallowEqual);
  const { catalogList } = useSelector(getOrders, shallowEqual);
  const [search, setSearch] = useState('');
  const [searchReg, setSearchReg] = useState(null);
  const [isShowList, setShowList] = useState(false);

  const { label, showData, toggleMenu } = props;

  // useEffect(() => {
  //   if (showList) dispatch(getCatalogDb());
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isShowList]);

  // const searchListener = useCallback((e) => {
  //     //console.log(e.target?.classList.contains('searchClick-js'));
  //     console.log(isShowList);
  //     const isExistsName = e.target?.classList?.contains('searchClick-js');
  //     if (isShowList && !isExistsName) setShowList(false);
  //     //
  //   },
  //   [],
  // );

  useEffect(() => {
    const searchListener = (e: any) => {
      //console.log(isShowList);
      const isExistsClass = e.target?.classList?.contains('searchClick-js');
      if (isShowList && !isExistsClass) setShowList(false);
    };

    if (isShowList) document.addEventListener('click', searchListener);
    if (!isShowList) document.removeEventListener('click', searchListener);

    return () => {
      document.removeEventListener('click', searchListener);
    };
    //
  }, [isShowList]);

  // useEffect(() => {
  //   if (!!errorLoad) setError(errorLoad);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [errorLoad]);

  // useEffect(() => {
  //   if (clear) {
  //     //setValue('');
  //     setSearch(null);
  //     setSearchReg(null);
  //     setClear(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [clear]);

  const filteredList = useMemo(
    () => catalogList?.filter(({ code, name }) => name?.match(searchReg) || code?.match(searchReg)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchReg],
  );

  useEffect(() => {
    if (!filteredList?.length && !!searchReg) setShowList(false);
  }, [filteredList?.length, searchReg]);

  const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val === ' ') return;
    if (showData) setShowList(true);
    if (loading) return;

    toggleMenu(false)();
    setSearch(val);
    //setValue(val);

    if (val.length <= 0) {
      setSearchReg(null);
      setShowList(false);
      return;
    }

    const reg = new RegExp(`${val}`, 'i');
    setSearchReg(reg);
  };

  const handleNavigate = (product: Product) => () => {
    //setValue(val);
    setShowList(false);
    navigate(`/catalog/${product.category[0]}/${product.code}`);
    setSearch('');
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    //document.getElementById('header-search').focus();
    if (!!filteredList.length || !searchReg) searchRef.current.focus();
    else navigate(`/search?text=${search}`);
    //else navigate(`/search/${search}`);
  };

  return (
    <div className="searchWrapper searchClick-js">
      <form action="#" id="header-search-form" className="searchForm" onSubmit={handleSearch}>
        <input
          ref={searchRef}
          id="header-search"
          type="search"
          name="search" //parts
          className="field search searchClick-js"
          placeholder={label}
          autoComplete="off"
          //required={required}
          value={search}
          onChange={changeSearch}
          //onClick={() => setShowList(!isShowList)}
          //pattern="[A-Za-zА-Яа-яЁё0-9\-._\s]+"
          maxLength={150}
        />
        <button
          type="submit"
          className="searchBtn mainBtn mainBtnHover searchClick-js"
          aria-label="search parts"
          //onClick={handleSearch}
        >
          {SearchBtn}
        </button>
      </form>

      {isShowList && (
        <ul className="dataList searchClick-js">
          {loading && (
            <div className="dialogLoading">
              <CircularProgress className="progress" />
            </div>
          )}

          {!!filteredList?.length &&
            !!searchReg &&
            filteredList?.map((item: Product) => (
              <li
                key={item.id}
                className="dataList__item searchClick-js"
                onClick={handleNavigate(item)}
              >
                <p className="name">{item.name}</p>
                <p className="code">
                  Артикул: <span>{item.code}</span>
                </p>
              </li>
            ))}

          {!filteredList?.length && !!searchReg && (
            <li className="dataList__item listEmpty searchClick-js">Ничего не найдено</li>
          )}

          {/* {!filteredList.length &&
            !searchReg &&
            !loading &&
            !!cityList.length &&
            cityList?.map((item: City, id: number) => (
              <li key={id} className="item" onClick={handleSelectCity(item.name)}>
                {item.name}
              </li>
            ))} */}
        </ul>
      )}
    </div>
  );
});

export default SearchParts;
