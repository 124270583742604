import { RootState } from '..';

export function selectAuth(state: RootState) {
  return state.auth;
}

// Auth

export function authLoading(state: RootState) {
  return state.auth.request.status === 1;
}

export function authLoaded(state: RootState) {
  return state.auth.request.status === 2;
}

export function authFailure(state: RootState) {
  return state.auth.request.error;
}

// User

export function userLoading(state: RootState) {
  return state.auth.requestUser.status === 1;
}

export function userLoaded(state: RootState) {
  return state.auth.requestUser.status === 2;
}

export function userFailure(state: RootState) {
  return state.auth.requestUser.error;
}

export function viewedUserPending(state: RootState) {
  return state.auth.requestViewed.status === 1;
}

// export function avatarLoaded(state: RootState) {
//   return state.profile.request.status === 5;
// }

export function viewedUserFailure(state: RootState) {
  return state.auth.requestViewed.error;
}

export function userDeletedLoad(state: RootState) {
  return state.auth.requestUser.status === 10;
}

export function userDeleted(state: RootState) {
  return state.auth.requestUser.status === 11;
}
