import React, { useState } from 'react';
//import { useDispatch } from 'react-redux';
import { Link, NavigateFunction, useLocation } from 'react-router-dom';
import { MenuLink, User } from '@/utils/mainTypes';
import { ListArrow } from '@/assets/images/Icons';

interface Props {
  menuEl: any;
  list: Array<MenuLink>;
  toggleMenu: any;
  authed?: boolean;
  user?: User;
  navigate?: NavigateFunction;
}

const DropMenu: React.FC<Props> = React.memo((props: Props) => {
  //const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [subMenuList, setSubMenuList] = useState(null);

  const { menuEl, list, toggleMenu } = props;

  const catalog = list?.find(({ path }) => path === '/catalog');

  // const handleProfile = () => {
  //   if (!!user.id) navigate('/profile');
  // };

  const setLinkClass = (path: string) => {
    const newReg = new RegExp(path);
    const regPath = pathname.match(newReg);

    if (pathname === path) return 'menuList__item menuList__item_active';
    else if (!!regPath) return 'menuList__item menuList__item_active';
    else return 'menuList__item';
  };

  const onOverLink = (subList: Array<MenuLink>) => () => {
    if (!!subList?.length) setSubMenuList(subList);
    else setSubMenuList(null);
  };

  return (
    <div ref={menuEl} className="headerDropMenu menuMedia">
      <div className="wrapper container">
        <div className="menuListWrapper">
          {/* <a href="tel:+79950117705" className="linkTel">
            8(995)011-77-05
          </a>
          <span className="text">Заявки и консультации</span> */}
          <ul className="menuList">
            {catalog?.subMenu?.map((link, idx) => (
              <React.Fragment key={idx}>
                {link?.show && (
                  <li className={setLinkClass(link.path)} onMouseOver={onOverLink(link?.subMenu)}>
                    <Link to={link.path} className="link" onClick={toggleMenu(false, link.path)}>
                      {link.name}
                    </Link>

                    {link?.subMenu?.length > 0 && <span className="arrowImage">{ListArrow}</span>}
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>

        {/* <hr className="menuMedia__divider" /> */}

        <div className="subMenuListWrapper">
          {!!subMenuList?.length && (
            <ul className="subMenuList">
              {subMenuList?.map((link: MenuLink, idx: number) => (
                <React.Fragment key={idx}>
                  {link?.show && (
                    <li className={setLinkClass(link.path)}>
                      <Link to={link.path} className="link" onClick={toggleMenu(false, link.path)}>
                        {link.name}
                      </Link>
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
});

export default DropMenu;
