import { RootState } from '..';

export function getUser(state: RootState) {
  return state.profile;
}

export function userLoading(state: RootState) {
  return state.profile.request.status === 1;
}

export function userLoaded(state: RootState) {
  return state.profile.request.status === 2;
}

export function userFailure(state: RootState) {
  return state.profile.request.error;
}

export function viewedUserPending(state: RootState) {
  return state.profile.requestViewed.status === 1;
}

// export function avatarLoaded(state: RootState) {
//   return state.profile.request.status === 5;
// }

export function viewedUserFailure(state: RootState) {
  return state.profile.requestViewed.error;
}

export function citiesPending(state: RootState) {
  return state.profile.requestData.status === 1;
}

export function citiesFailure(state: RootState) {
  return state.profile.requestData.error;
}

export function userDeletedLoad(state: RootState) {
  return state.profile.request.status === 10;
}

export function userDeleted(state: RootState) {
  return state.profile.request.status === 11;
}
