import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';

import { getUser } from '@/store/profile/selectors';
import { selectionPending, selectionLoaded, selectionFailure } from '@/store/feedback/selectors';
import { sendSelectionData } from '@/store/feedback/slicer';
import { checkReg, formatVisitDate } from '@/utils/methods';
import { mainColor, modalStyle } from '@/utils/constants';
import { CloseBtn } from '@/assets/images/Icons';
import { PropsModal } from '../types';

const currentYear = new Date().getFullYear();

const FeedbackPartsModal: React.FC<PropsModal> = React.memo((props: PropsModal) => {
  const dispatch = useDispatch();
  const { currUser } = useSelector(getUser, shallowEqual);
  const loading = useSelector(selectionPending, shallowEqual);
  const loaded = useSelector(selectionLoaded, shallowEqual);
  const feedbackError = useSelector(selectionFailure, shallowEqual);
  //const [select, setSelect] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+7');
  //const [text, setText] = useState('');
  const [selectionData, setSelectionData] = useState({ car: '', year: '', vin: '', text: '' });
  const [dialogError, setDialogError] = useState('');

  const { settings, selection, data } = props;
  const { openModal, setOpenModal } = settings;

  //console.log(data);

  useEffect(() => {
    if (openModal && !!data) {
      setSelectionData(data);
    }

    // if (openModal && currUser?.id) {
    //   setEmail(currUser?.email || '');
    // }
  }, [data, openModal]);

  const handleClose = () => {
    setOpenModal(false);
    setDialogError('');

    setTimeout(() => {
      setName('');
      setEmail('');
      setPhone('+7');
      setSelectionData({ car: '', year: '', vin: '', text: '' });
      //setSelect('');
      //setText('');
    }, 500);
  };

  useEffect(() => {
    if (!!loaded) handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!!feedbackError) setDialogError(feedbackError);
  }, [feedbackError]);

  // const changeThanks = (e: ChangeEvent) => {
  //   if (e.target.value.length <= 60) setSelect(e.target.value);
  //   setDialogError('');
  // };

  const changeData = (key: string) => (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const obj = { ...selectionData };
    obj[key] = e.target.value;

    setSelectionData(obj);
  };

  const changeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 50) setEmail(e.target.value);
  };

  // const changeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
  //   if (e.target.value.length <= 500) setText(e.target.value);
  // };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setDialogError('');

    const regs = checkReg([name, phone, email]);
    //const regsMsg = checkRegMsg(text);
    const formData = new FormData();

    if (!!regs) {
      setDialogError('Введите необходимые данные корректно');
      return;
    }
    //else setDialogError('');

    if (!currUser?.id && !email && !phone) {
      setDialogError('Введите номер телефона или Email');
      return;
    }

    if (!currUser?.id && !regs) {
      formData.append('title', '[Заявка на подбор запчастей]');
      //formData.append('title', '[Заявка на обратную связь]');
      //formData.append('theme', select);
      //formData.append('text', text);
      formData.append('name', name);
      formData.append('phone', phone);
      formData.append('email', email);
      formData.append('car', selectionData.car);
      formData.append('year', selectionData.year);
      formData.append('vin', selectionData.vin);
      formData.append('text', selectionData.text);

      dispatch(sendSelectionData(formData));
      //console.log(selectionData);
    }

    if (!!currUser?.id && !regs) {
      const obj = {
        //id: 1,
        //theme: select,
        date: formatVisitDate(),
        user: currUser,
        done: false,
        data: selectionData,
        //text: text,
      };

      //dispatch(sendSupportMsg(obj));
    }
  };

  return (
    <>
      <Dialog open={openModal} onClose={handleClose} PaperProps={{ sx: modalStyle }}>
        <div className="mainDialog feedbackDialog">
          <button className="closeBtn mainBtn" onClick={handleClose}>
            {CloseBtn}
          </button>
          {/* <DialogTitle className="heading">Ваше обращение</DialogTitle> */}
          <DialogTitle className="heading">Заявка на подбор</DialogTitle>

          <DialogContent className="content">
            <form id="feedback-form" method="POST" className="dialogForm" onSubmit={handleSubmit}>
              {!currUser?.id && (
                <>
                  <div className="fieldWrapper">
                    <span className="fieldLabel">Контактные данные</span>
                    <input
                      className="field"
                      type="name"
                      name="name"
                      placeholder="Ваше Имя*"
                      required
                      autoComplete="on"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      pattern="^[A-Za-zА-Яа-яЁё0-9\-_. s]+"
                      maxLength={60}
                    />
                  </div>

                  <input
                    className="field"
                    type="email"
                    name="email"
                    placeholder="Ваш E-mail"
                    //required
                    autoComplete="on"
                    value={email}
                    onChange={changeEmail}
                    pattern="^(.{1,})@(.{1,}).([A-z]{2,8})"
                    maxLength={50}
                  />

                  <input
                    className="field"
                    type="tel"
                    name="tel"
                    placeholder="+7 (777) 777-77-77"
                    //required
                    autoComplete="on"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    pattern="(^8|7|\+7)\s?[\(]{0,1}[0-9]{3}[\)]{0,1}\s?\d{3}[\-\s?]{0,1}\d{2}[\-\s?]{0,1}\d{2}"
                    maxLength={20}
                  />
                </>
              )}

              <div className="wrapper">
                <div className="fieldWrapper wrapper__width_car">
                  <span className="fieldLabel">Автомобиль</span>
                  <input
                    id="selection-parts-car"
                    type="text"
                    name="car"
                    className="field"
                    placeholder="Введите марку и модель*"
                    required
                    maxLength={100}
                    value={selectionData.car}
                    onChange={changeData('car')}
                    //pattern="[A-Za-zА-Яа-яЁё0-9\-_. s]+"
                  />
                </div>

                <div className="fieldWrapper wrapper__width_year">
                  {/* <span className="fieldLabel">Год выпуска</span> */}
                  <input
                    id="selection-parts-year"
                    type="number"
                    name="year"
                    className="field"
                    placeholder="Год*"
                    required
                    maxLength={4}
                    min={1960}
                    max={currentYear}
                    value={selectionData.year}
                    onChange={changeData('year')}
                    pattern="[0-9]+"
                  />
                </div>
              </div>

              <div className="fieldWrapper">
                {/* <span className="fieldLabel">VIN номер</span> */}
                <input
                  id="selection-parts-vin"
                  type="text"
                  name="vin"
                  className="field"
                  placeholder="Введите VIN номер*"
                  required
                  maxLength={50}
                  value={selectionData.vin}
                  onChange={changeData('vin')}
                  //pattern="[0-9]+"
                />
                {/* <p className="fieldHelp">
                  Подойдет номер кузова, например: LJ78-0009746. <br />
                  VIN или номер кузова гарантирует точный подбор
                </p> */}
              </div>

              <div className="fieldWrapper">
                <span className="fieldLabel">Какие запчасти ищете?</span>
                <textarea
                  id="selection-parts-parts"
                  name="parts"
                  className="field textarea"
                  placeholder="Введите названия запчастей через запятую*"
                  required
                  maxLength={700}
                  rows={4}
                  value={selectionData.text}
                  onChange={changeData('text')}
                />
              </div>

              {/* <textarea
                className="textarea"
                name="message"
                id="message"
                placeholder="Описание"
                required
                rows={5}
                maxLength={500}
                spellCheck="true"
                value={text}
                onChange={changeText}
              /> */}

              <div className="policy">
                {/* <Checkbox
                  className="checkbox"
                  id="policy"
                  required
                  defaultChecked
                  size="medium"
                  sx={{ color: mainColor, '&.Mui-checked': { color: mainColor } }}
                  // htmlFor="policy"
                /> */}
                <p className="label">
                  Отправляя, Вы разрешаете обработку персональных данных и соглашаетесь c
                  <a href="/policy" target="_blank" className="link">
                    Политикой конфиденциальности
                  </a>
                </p>
              </div>

              <button className="submitBtn mainBtn" type="submit">
                Отправить
              </button>
            </form>
          </DialogContent>

          {!!dialogError && <p className="dialogError">{dialogError}</p>}
          {loading && (
            <div className="dialogLoading">
              <CircularProgress className="progress" />
            </div>
          )}
        </div>
      </Dialog>

      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        message="Note archived"
        //action={action}
      /> */}
    </>
  );
});

export default FeedbackPartsModal;
